import React from 'react';
import { useMutation } from '@apollo/client';
import BigNumber from 'bignumber.js';
import { DateTime } from 'luxon';
import { Spinner } from 'react-bootstrap';
import { CANCEL_OFFER, CLOSE_OFFER } from 'api/mutations/offer';
import { CLOSE_POSITION } from 'api/mutations/positions';
import { sendAndSignTransactions } from 'api/transaction';
import {
  ArrowRightIcon,
  ArrowUpIcon,
  CalendarIcon,
  ClosePositionIcon,
  DatabaseIcon,
  PresentationIcon,
  ScaleIcon
} from 'assets/img';
import { TOKEN_PLACEHOLDER } from 'config';
import './ActivityItem.scss';
import { toFixedWithoutRounding } from 'helpers';
import { useGetActiveTransactionsStatus } from 'hooks';
import { BorrowingPosition, PositionStatus } from 'types';
import { ActivityData } from './ActivityData';
interface ActivityItemBorrowingProps {
  item: BorrowingPosition;
}
enum Action {
  CANCEL_OFFER = 'cancelOffer',
  CLOSE_POSITION = 'closePosition',
  CLOSE_OFFER = 'closeOffer',
  NONE = 'none'
}
export const ActivityItemBorrowing = ({ item }: ActivityItemBorrowingProps) => {
  const action: Action =
    item.status === PositionStatus.INACTIVE
      ? Action.CANCEL_OFFER
      : item.status === PositionStatus.ACTIVE && item.swappedToken
      ? Action.CLOSE_POSITION
      : item.status === PositionStatus.ACTIVE && !item.swappedToken
      ? Action.CLOSE_OFFER
      : Action.NONE;

  const [performAction, { data: actionData, loading: loadingAction }] =
    useMutation(
      action === Action.CANCEL_OFFER
        ? CANCEL_OFFER
        : action === Action.CLOSE_OFFER
        ? CLOSE_OFFER
        : CLOSE_POSITION
    );

  const { pending } = useGetActiveTransactionsStatus();

  const loading = loadingAction || pending;

  const borrowedNumber = item.borrowedAmount
    ? new BigNumber(item.borrowedAmount || 0)
        .shiftedBy(-item.token.decimals)
        .toNumber()
    : undefined;
  const collateralNumber = item.collateralAmount
    ? new BigNumber(item.collateralAmount || 0)
        .shiftedBy(-item.token.decimals)
        .toNumber()
    : undefined;

  const swappedTokenNumber = item.swappedToken
    ? toFixedWithoutRounding(
        new BigNumber(item.swappedToken.amount)
          .shiftedBy(-item.swappedToken.token.decimals)
          .toNumber(),
        4
      )
    : undefined;
  const borrowedValueNumber = item.swappedToken
    ? toFixedWithoutRounding(
        new BigNumber(item.swappedToken.currentBorrowedValue)
          .shiftedBy(-item.token.decimals)
          .toNumber(),
        4
      )
    : undefined;

  const liquidationNumber = item.swappedToken
    ? Number(item.swappedToken.liquidationPrice)
    : undefined;

  const takeProfitNumber = item.swappedToken
    ? Number(item.swappedToken.takeProfitPrice)
    : undefined;

  const stopLossNumber = item.swappedToken
    ? Number(item.swappedToken.stopLossPrice)
    : undefined;

  const endDateLabel = () => {
    if (item.endDateTs) {
      const endDateTs = DateTime.fromMillis(item.endDateTs * 1000);
      const { days, hours, minutes } = endDateTs
        .diffNow(['days', 'hours', 'minutes', 'seconds'])
        .toObject();

      if (endDateTs < DateTime.now()) {
        return '0D:0H:0M';
      }

      return `${days ? days + 'D:' : ''}${hours}H:${minutes}M`;
    } else {
      return '-';
    }
  };

  const onClickAction = () => {
    if (action === Action.NONE) return;
    let payload = {};
    if (action === Action.CANCEL_OFFER) {
      payload = { ...payload, offerId: item.offerId, offerType: 'Borrowing' };
    } else {
      payload = { ...payload, tokenId: item.token.identifier };
    }
    performAction({
      variables: payload
    });
  };

  React.useEffect(() => {
    if (actionData && actionData.action) {
      const transactionDisplayInfoType = {
        processingMessage: `${
          action === Action.CANCEL_OFFER ? 'Cancel' : 'Close'
        } the ${action === Action.CLOSE_POSITION ? 'position' : 'offer'}`,
        errorMessage: `An error has occurred while ${
          action === Action.CANCEL_OFFER ? 'canceling' : 'closing'
        } the ${action === Action.CLOSE_POSITION ? 'position' : 'offer'}`,
        successMessage: `The ${
          action === Action.CLOSE_POSITION ? 'position' : 'offer'
        } has been ${
          action === Action.CANCEL_OFFER ? 'canceled' : 'closed'
        } successfully`
      };
      sendAndSignTransactions(actionData.action, transactionDisplayInfoType);
    }
  }, [actionData?.action]);

  return (
    <div className='activity-item'>
      <ActivityData
        icon={
          <img className='activity-item__token-icon' src={item.token.icon} />
        }
        description='Borrowed:'
        title={`${borrowedNumber ?? '-'} ${item.token.ticker}`}
        subtitle='34% of your portfolio'
      />

      <ActivityData
        icon={
          <div className='position-relative'>
            <img className='activity-item__token-icon' src={item.token.icon} />
            <ArrowUpIcon className='activity-item__mini-icon' />
          </div>
        }
        description='Collateral:'
        title={`${collateralNumber ?? '-'} ${item.token.ticker}`}
        subtitle='leverage: 3x'
      />

      <ActivityData
        icon={
          <div className='position-relative'>
            <img className='activity-item__token-icon' src={item.token.icon} />
            <DatabaseIcon className='activity-item__mini-icon' />
          </div>
        }
        description='Total tokens:'
        title={`${
          borrowedNumber !== undefined && collateralNumber !== undefined
            ? borrowedNumber + collateralNumber
            : '-'
        } ${item.token.ticker}`}
        subtitle='borrowed + colateral'
      />

      <ActivityData
        icon={
          <div className='position-relative'>
            <img className='activity-item__token-icon' src={item.token.icon} />
            <img
              className='activity-item__token-icon activity-item__second-token'
              src={
                item.swappedToken
                  ? item.swappedToken.token.icon
                  : TOKEN_PLACEHOLDER
              }
            />
            <ArrowRightIcon className='activity-item__centered-icon' />
          </div>
        }
        description='Swapped token:'
        title={`${swappedTokenNumber ? swappedTokenNumber : '-'} ${
          item.swappedToken ? item.swappedToken.token.ticker : ''
        }`}
        subtitle='make some profit'
      />

      <ActivityData
        icon={<ScaleIcon className='activity-item__icon-big' />}
        description='Borrowed value:'
        title={`${borrowedValueNumber ? borrowedValueNumber : '-'} ${
          item.swappedToken ? item.token.ticker : ''
        }`}
        subtitle='current value of swapped token'
      />

      <ActivityData
        icon={<PresentationIcon className='activity-item__icon-big' />}
        description='Liquidation price:'
        title={`${liquidationNumber ? liquidationNumber : '-'} ${
          item.swappedToken
            ? item.token.ticker + '/' + item.swappedToken.token.ticker
            : ''
        }`}
        subtitle='keep an eye on this'
      />

      {takeProfitNumber && (
        <ActivityData
          icon={<PresentationIcon className='activity-item__icon-big' />}
          description='TP price:'
          title={`${takeProfitNumber} ${
            item.swappedToken
              ? item.token.ticker + '/' + item.swappedToken.token.ticker
              : ''
          }`}
          subtitle='keep an eye on this'
        />
      )}

      {stopLossNumber && (
        <ActivityData
          icon={<PresentationIcon className='activity-item__icon-big' />}
          description='SL price:'
          title={`${stopLossNumber} ${
            item.swappedToken
              ? item.token.ticker + '/' + item.swappedToken.token.ticker
              : ''
          }`}
          subtitle='keep an eye on this'
        />
      )}

      <ActivityData
        icon={<CalendarIcon className='activity-item__icon-big' />}
        description='Time left:'
        title={endDateLabel()}
        subtitle='until end of contract'
      />

      <ActivityData
        icon={
          loading ? (
            <Spinner as='span' animation='border' variant='primary' />
          ) : (
            <ClosePositionIcon className='activity-item__icon-big' />
          )
        }
        titleElement={
          !loading ? (
            <span className='activity-item__title'>
              {action === Action.CANCEL_OFFER ? 'cancel' : 'close'}
              <br />
              {action === Action.CLOSE_POSITION ? 'position' : 'offer'}
            </span>
          ) : undefined
        }
        subtitle='load your bags'
        onClick={onClickAction}
      />
    </div>
  );
};
