import { gql } from '@apollo/client';

export const GET_LENDING_POSITIONS = gql`
  query GET_LENDING_POSITIONS {
    lendingPositions {
      amount
      offerId
      token {
        identifier
        name
        owner
        ticker
        circulatingSupply
        assets {
          pngUrl
        }
        supply
        decimals
        isPaused
        balance
      }
      apr
      endDateTs
      reward {
        positionRewardAmount
        platformToken {
          identifier
          name
          owner
          ticker
          circulatingSupply
          assets {
            pngUrl
          }
          supply
          decimals
          isPaused
          balance
        }
      }
      status
    }
  }
`;

export const GET_BORROWING_POSITIONS = gql`
  query GET_BORROWING_POSITIONS {
    borrowingPositions {
      borrowedAmount
      collateralAmount
      leverage
      offerId
      token {
        identifier
        name
        owner
        ticker
        circulatingSupply
        assets {
          pngUrl
        }
        supply
        decimals
        balance
      }
      endDateTs
      swappedToken {
        currentBorrowedValue
        token {
          identifier
          name
          owner
          ticker
          circulatingSupply
          assets {
            pngUrl
          }
          supply
          decimals
          balance
        }
        amount
        liquidationPrice
        stopLossPrice
        takeProfitPrice
        __typename
      }
      status
    }
  }
`;

export const TRADE_DETAILS = gql`
  query TRADE_DETAILS($tokenIn: String!, $tokenOut: String!) {
    tradeDetails(tokenIn: $tokenIn, tokenOut: $tokenOut) {
      exchangeRate
      amountIn
      amountOut
      entryPrice
      liquidationPrice
    }
  }
`;
