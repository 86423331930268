import { useQuery } from '@apollo/client';
import { GET_SECURITY_ADMINS } from 'api/query';

const useGetSecurityAdmins = (): { data: string[] | undefined } => {
  const { data } = useQuery(GET_SECURITY_ADMINS);

  return {
    data: data?.securityAdmins
  };
};

export { useGetSecurityAdmins };
