import React, { useContext } from 'react';
import { EsdtToken } from 'types';

export interface HomeContextModel {
  selectedToken?: EsdtToken;
  setSelectedToken: React.Dispatch<React.SetStateAction<EsdtToken | undefined>>;
  tokenAmount: string;
  setTokenAmount: React.Dispatch<React.SetStateAction<string>>;
  period: string;
  setPeriod: React.Dispatch<React.SetStateAction<string>>;
  apr: string;
  setApr: React.Dispatch<React.SetStateAction<string>>;
}

export const HomeContext = React.createContext<HomeContextModel | null>(null);

HomeContext.displayName = 'HomeContext';

export function useHomeContextConfig(): HomeContextModel {
  const context = useContext(HomeContext);
  if (context == null) {
    throw new Error('HomeContext is null');
  }

  return context;
}
