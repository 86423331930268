import { useEffect, useMemo, useState } from 'react';
import { TradeContext } from './trade-context';

export type TradeLimit = {
  amount: string;
  checked: boolean;
  error: string | undefined;
};

const defaultLimit = {
  amount: '',
  checked: false,
  error: undefined
};

export const TradeContextProvider = ({ children }: { children: any }) => {
  const [takeProfitLimit, setTakeProfitLimit] =
    useState<TradeLimit>(defaultLimit);
  const [stopLossLimit, setStopLossLimit] = useState<TradeLimit>(defaultLimit);

  useEffect(() => {
    if (!takeProfitLimit.checked) {
      setTakeProfitLimit((prev) => {
        return { ...prev, error: undefined, amount: '' };
      });
      return;
    }

    if (!stopLossLimit.checked) {
      setStopLossLimit((prev) => {
        return { ...prev, error: undefined, amount: '' };
      });
    }
  }, [takeProfitLimit.checked, stopLossLimit.checked]);

  const resetLimits = () => {
    setTakeProfitLimit(defaultLimit);
    setStopLossLimit(defaultLimit);
  };

  const limitsError = useMemo(() => {
    return takeProfitLimit.error || stopLossLimit.error;
  }, [takeProfitLimit.error, stopLossLimit.error]);

  return (
    <TradeContext.Provider
      value={{
        takeProfitLimit,
        setTakeProfitLimit,
        stopLossLimit,
        setStopLossLimit,
        resetLimits,
        limitsError
      }}
    >
      {children}
    </TradeContext.Provider>
  );
};
