import React from 'react';
import { useMutation } from '@apollo/client';
import { useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { DateTime } from 'luxon';
import { Spinner } from 'react-bootstrap';
import { CANCEL_OFFER } from 'api/mutations/offer';
import { sendAndSignTransactions } from 'api/transaction';
import {
  AprIcon,
  CircleActiveIcon,
  CircleInactiveIcon,
  CalendarIcon,
  ActivityEditIcon,
  ClosePositionIcon
} from 'assets/img';
import './ActivityItem.scss';
import { LendingPosition, PositionStatus } from 'types';
import { ActivityData } from './ActivityData';
interface ActivityItemLendingProps {
  item: LendingPosition;
}
export const ActivityItemLending = ({ item }: ActivityItemLendingProps) => {
  const [
    performCancelOffer,
    { data: cancelOfferData, loading: loadingCancelOffer }
  ] = useMutation(CANCEL_OFFER);

  const { pending } = useGetActiveTransactionsStatus();

  const loading = pending || loadingCancelOffer;

  const tokenTitle = `${new BigNumber(item.amount)
    .shiftedBy(-item.token.decimals)
    .toNumber()} ${item.token.ticker}`;

  const endDateLabel = () => {
    if (item.endDateTs) {
      const endDateTs = DateTime.fromMillis(item.endDateTs * 1000);
      const { days, hours, minutes } = endDateTs
        .diffNow(['days', 'hours', 'minutes', 'seconds'])
        .toObject();

      if (endDateTs < DateTime.now()) {
        return '0D:0H:0M';
      }

      return `${days ? days + 'D:' : ''}${hours}H:${minutes}M`;
    } else {
      return '-';
    }
  };

  const onCancelPosition = () => {
    if (
      item?.status === PositionStatus.INACTIVE &&
      item?.offerId !== undefined
    ) {
      performCancelOffer({
        variables: {
          offerId: item.offerId,
          offerType: 'Lending'
        }
      });
    }
  };

  React.useEffect(() => {
    if (cancelOfferData && cancelOfferData.action) {
      const transactionDisplayInfoType = {
        processingMessage: 'Cancel the offer',
        errorMessage: 'An error has occurred while canceling the offer',
        successMessage: 'The offer has been canceled successfully'
      };
      sendAndSignTransactions(
        cancelOfferData.action,
        transactionDisplayInfoType
      );
    }
  }, [cancelOfferData?.action]);

  return (
    <div className='activity-item'>
      <ActivityData
        icon={
          <img className='activity-item__token-icon' src={item.token.icon} />
        }
        title={tokenTitle}
        subtitle='34% of your portfolio'
      />

      <ActivityData
        icon={<AprIcon className='activity-item__icon' />}
        title={`APR: ${item.apr} %`}
        subtitle='1.5% below price'
      />

      <ActivityData
        icon={<CalendarIcon className='activity-item__icon' />}
        description='Time left:'
        title={endDateLabel()}
        subtitle='Active since 2 days ago'
      />

      {/* <ActivityData
        icon={
          <div className='position-relative'>
            <img
              className='activity-item__token-icon'
              src={TOKEN_PLACEHOLDER}
            />
            <ArrowDownIcon className='activity-item__mini-icon' />
          </div>
        }
        title=''
        subtitle='earned so far'
      /> */}

      {/* <ActivityData
        icon={
          <div className='position-relative'>
            <RewardsIcon className='activity-item__icon' />
            <ArrowDownIcon className='activity-item__mini-icon' />
          </div>
        }
        title='Reward: 43523 LVRG'
        subtitle='earned so far'
      /> */}

      <ActivityData
        icon={
          item.status === PositionStatus.ACTIVE ? (
            <CircleActiveIcon className='activity-item__icon' />
          ) : (
            <CircleInactiveIcon className='activity-item__icon' />
          )
        }
        title={item.status}
      />

      <ActivityData
        icon={
          loading ? (
            <Spinner as='span' animation='border' size='sm' />
          ) : item.status === PositionStatus.INACTIVE ? (
            <ClosePositionIcon className='activity-item__icon-big' />
          ) : (
            <ActivityEditIcon className='activity-item__icon' />
          )
        }
        title={
          loading
            ? undefined
            : item.status === PositionStatus.INACTIVE
            ? 'Cancel position'
            : 'Edit'
        }
        subtitle={false ? 'earn more' : undefined}
        onClick={() => {
          item.status === PositionStatus.INACTIVE && onCancelPosition();
        }}
        disabled={item.status !== PositionStatus.INACTIVE}
      />
    </div>
  );
};
