import { useEffect, useMemo, useState } from 'react';
import { faInfoCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';
import BigNumber from 'bignumber.js';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { sendAndSignTransactions } from 'api/transaction';
import ActionOrConnect from 'components/ActionOrConnect';
import TakeProfitStopLoss from 'components/TakeProfitStopLoss/TakeProfitStopLoss';
import { egldIdentifier } from 'config';
import { formatTicker } from 'helpers/formatTicker';
import { useGetActiveTransactionsStatus, useGetTokens } from 'hooks';
import { TradePairSelect, TradingPair } from './Components';
import { AmountInput } from './Components/AmountInput/AmountInput';
import { Leverage } from './Components/LeverageOption';
import { TradeLeverage } from './Components/TradeLeverage';
import { TradeToggleButton } from './Components/TradeToggleButton';
import { useTradeContext } from './context';
import { useGetBorrowAndOpenPosition } from './hooks/useGetBorrowAndOpenPosition';
import { useGetTradingPairs } from './hooks/useGetTradingPairs';
import { TradeType } from './Models';
import './Trade.scss';

export const TradeContent = () => {
  const [tradeType, setTradeType] = useState(TradeType.LONG);
  const [tradingPair, setTradingPair] = useState<TradingPair>();
  const [amount, setAmount] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [leverage, setLeverage] = useState<Leverage>({ value: '2' });

  const { stopLossLimit, takeProfitLimit, resetLimits, limitsError } =
    useTradeContext();

  const { data: tokens, loading: loadingTokens } = useGetTokens();
  const { data: tradingPairs, loading: loadingTradingPairs } =
    useGetTradingPairs();

  const { pending, success } = useGetActiveTransactionsStatus();

  const [tokenIn, tokenOut] = useMemo(() => {
    return tradeType === TradeType.LONG
      ? [tradingPair?.secondToken, tradingPair?.firstToken]
      : [tradingPair?.firstToken, tradingPair?.secondToken];
  }, [tradeType, tradingPair]);

  const shortButtonEnabled = useMemo(() => {
    return (
      tradingPair?.secondToken?.identifier === egldIdentifier ||
      tokens.find(
        (token) => token.identifier === tradingPair?.secondToken?.identifier
      ) !== undefined
    );
  }, [tradingPair, tokens]);

  const amountWithDecimals = useMemo(() => {
    if (amount.length === 0) {
      return new BigNumber(0);
    }

    return new BigNumber(amount).shiftedBy(tokenIn?.decimals ?? 0);
  }, [amount, tokenIn, tradeType]);

  const {
    data: borrowAndOpenPositionData,
    loading: borrowAndOpenPositionLoading,
    error: borrowAndOpenPositionError,
    getBorrowAndOpenPositionData,
    resetBorrowAndOpenPositionData
  } = useGetBorrowAndOpenPosition();

  const hasBalance = useMemo(() => {
    return amountWithDecimals.isLessThanOrEqualTo(
      borrowAndOpenPositionData?.balance ?? 0
    );
  }, [borrowAndOpenPositionData, amountWithDecimals]);

  useEffect(() => {
    const takeProfitAmount =
      !tokenIn || takeProfitLimit.amount.length === 0
        ? ''
        : new BigNumber(takeProfitLimit.amount)
            .shiftedBy(tokenIn.decimals)
            .toFixed();

    const stopLossAmount =
      !tokenIn || stopLossLimit.amount.length === 0
        ? ''
        : new BigNumber(stopLossLimit.amount)
            .shiftedBy(tokenIn.decimals)
            .toFixed();

    if (takeProfitLimit.error || stopLossLimit.error) {
      return;
    }

    getBorrowAndOpenPositionData(
      tokenIn,
      tokenOut,
      amountWithDecimals,
      Number(leverage.value),
      takeProfitAmount,
      stopLossAmount
    );
  }, [
    tradeType,
    tokenIn,
    tokenOut,
    amountWithDecimals,
    leverage,
    takeProfitLimit.amount,
    stopLossLimit.amount,
    stopLossLimit.error,
    takeProfitLimit.error
  ]);

  const hasError =
    borrowAndOpenPositionData === undefined ||
    borrowAndOpenPositionError !== undefined ||
    borrowAndOpenPositionData.transactions.length === 0 ||
    limitsError !== undefined;

  const isLoading =
    pending ||
    borrowAndOpenPositionLoading ||
    loadingTradingPairs ||
    loadingTokens;

  useEffect(() => {
    if (tradingPairs.length === 0) return;

    setTradingPair(tradingPairs[0]);
  }, [tradingPairs]);

  useEffect(() => {
    if (success) {
      setLeverage({ value: '2' });
      setAmount('');
      resetLimits();
    }
  }, [success]);

  useEffect(() => {
    resetBorrowAndOpenPositionData();
    resetLimits();
  }, [tradeType]);

  useEffect(() => {
    resetLimits();
  }, [tokenIn, tokenOut]);

  useEffect(() => {
    if (!borrowAndOpenPositionData) {
      setErrorMessage(undefined);
      return;
    }

    if (!hasBalance) {
      setErrorMessage('Not enough balance.');
      return;
    }

    if (borrowAndOpenPositionData.transactions.length === 0) {
      setErrorMessage(
        'Could not find any matching offers. Consider modifying your trade parameters.'
      );
      return;
    }

    setErrorMessage(undefined);
  }, [hasBalance, borrowAndOpenPositionData]);

  const handleOnClick = () => {
    if (
      borrowAndOpenPositionData === undefined ||
      borrowAndOpenPositionData.transactions.length === 0
    ) {
      return;
    }

    const transactionDisplayInfoType = {
      processingMessage: 'Open position',
      errorMessage: 'An error has occurred while opening the position',
      successMessage: 'The position has been opened successfully'
    };
    sendAndSignTransactions(
      borrowAndOpenPositionData.transactions,
      transactionDisplayInfoType
    );
  };

  const buttonTitle = useMemo(() => {
    if (tradingPair?.firstToken === undefined) {
      return tradeType === TradeType.LONG ? 'Buy/Long' : 'Sell/Short';
    }

    const ticker = formatTicker(tradingPair.firstToken.identifier);

    return tradeType === TradeType.LONG
      ? `Buy/Long ${ticker}`
      : `Sell/Short ${ticker}`;
  }, [tradeType, tradingPair]);

  const marketOrderTooltip = (
    <Tooltip id='tooltip'>
      Market order is immediately matched to the best available market offer.
    </Tooltip>
  );

  return (
    <div className='swap-card__radius swap-card__background'>
      <div className='p-4'>
        <div className='d-flex flex-column align-items-center'>
          <div className='swap-card-text__size-normal swap-card-text__white mb-4'>
            Trade
          </div>
          <TradePairSelect
            loading={loadingTradingPairs || loadingTokens}
            tradingPairs={tradingPairs}
            selectedTradingPair={tradingPair}
            onTradingPairChanged={setTradingPair}
          />
          <TradeToggleButton
            value={tradeType}
            onValueChanged={setTradeType}
            shortButtonEnabled={shortButtonEnabled}
          />
          <AmountInput
            amount={amount}
            disabled={isLoading}
            placeholder={`Collateral in ${formatTicker(tokenIn?.identifier)}`}
            setAmount={setAmount}
          />
          <TradeLeverage leverage={leverage} setLeverage={setLeverage} />
          <OverlayTrigger placement='top' overlay={marketOrderTooltip}>
            <div className='d-flex flex-row align-items-center justify-content-center mt-3 trade__container w-100 py-2 px-3'>
              <FontAwesomeIcon
                className='trade__container__icon'
                icon={faInfoCircle}
              />{' '}
              Market
            </div>
          </OverlayTrigger>
          {amount.length > 0 && tokenIn !== undefined && (
            <div className='d-flex flex-row w-100 mt-3'>
              <span className='trade__text flex-grow-1'>Borrowing:</span>
              <span className='trade__text'>
                {new BigNumber(amount)
                  .multipliedBy(leverage.value)
                  .minus(amount)
                  .toFixed()}{' '}
                {formatTicker(tokenIn?.identifier)}
              </span>
            </div>
          )}
          {borrowAndOpenPositionData && !borrowAndOpenPositionLoading && (
            <div className='d-flex flex-row w-100 mt-3'>
              <span className='trade__text flex-grow-1'>Available</span>
              <span className='trade__text'>
                <FormatAmount
                  value={borrowAndOpenPositionData.balance}
                  decimals={tokenIn?.decimals}
                  egldLabel={formatTicker(tokenIn?.identifier)}
                />
              </span>
            </div>
          )}
          {borrowAndOpenPositionData?.feePercentage && (
            <div className='d-flex flex-row w-100 mt-3'>
              <span className='trade__text flex-grow-1'>Fee percentage</span>
              <span className='trade__text'>
                {borrowAndOpenPositionData.feePercentage}% APY
              </span>
            </div>
          )}
          {borrowAndOpenPositionData?.exchangeRate && (
            <div className='d-flex flex-row w-100 mt-3'>
              <span className='trade__text flex-grow-1'>Current price</span>
              <span className='trade__text'>
                <FormatAmount
                  value={borrowAndOpenPositionData.exchangeRate}
                  decimals={tokenIn?.decimals}
                  egldLabel={formatTicker(tokenIn?.identifier)}
                />{' '}
                / {formatTicker(tokenOut?.identifier)}
              </span>
            </div>
          )}
          {borrowAndOpenPositionData?.liquidationPrice && (
            <div className='d-flex flex-row w-100 mt-3'>
              <span className='trade__text flex-grow-1'>Liquidation price</span>
              <span className='trade__text'>
                {borrowAndOpenPositionData?.liquidationPrice}{' '}
                {formatTicker(tokenIn?.identifier)} /{' '}
                {formatTicker(tokenOut?.identifier)}
              </span>
            </div>
          )}
          <TakeProfitStopLoss
            liquidationPrice={borrowAndOpenPositionData?.liquidationPrice}
            tokenIn={tokenIn}
            tokenOut={tokenOut}
            entryPrice={borrowAndOpenPositionData?.exchangeRate}
            positionSize={borrowAndOpenPositionData?.amountOut}
          />
          <ActionOrConnect className='xlvg-button xlvg-button-connect mt-4 mb-2'>
            <button
              className={`xlvg-button xlvg-button-radius-max mt-4 mb-2 xlvg-button--${
                tradeType === TradeType.LONG ? 'green' : 'red'
              } xlvg-button--${hasError || !hasBalance ? 'inactive' : ''}`}
              disabled={hasError || isLoading || !hasBalance}
              onClick={handleOnClick}
            >
              {isLoading ? (
                <Spinner as='span' animation='border' size='sm' />
              ) : (
                buttonTitle
              )}
            </button>
          </ActionOrConnect>
          {errorMessage && (
            <span className='trade__text trade__text--warning mt-3'>
              <FontAwesomeIcon className='mr-2' icon={faWarning} />
              {errorMessage}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
