import { gql } from '@apollo/client';

export const OPEN_POSITION = gql`
  mutation OPEN_POSITION($args: OpenPositionArgs!) {
    openPosition(args: $args) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
      signature
      status
    }
  }
`;
export const CLOSE_POSITION = gql`
  mutation CLOSE_POSITION($tokenId: String!) {
    action: closePosition(tokenId: $tokenId) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
      status
      signature
    }
  }
`;
