import React from 'react';
import Select, { SingleValue } from 'react-select';
import { selectColourStyles } from 'components/Select/util';
import { TOKEN_PLACEHOLDER } from 'config';
// import { useGetTokens } from 'hooks';
import { EsdtToken } from 'types';
import './SwapSelect.scss';

interface SwapSelectProps {
  options: EsdtToken[] | undefined;
  optionSelected: EsdtToken | undefined;
  loading: boolean;
  preselectFirst?: boolean;
  onSelect: (value: EsdtToken | undefined) => void;
}
export const SwapSelect = ({
  options,
  loading,
  onSelect,
  preselectFirst = false,
  optionSelected
}: SwapSelectProps) => {
  // const { data: options, loading } = useGetTokens();
  const formatOptionLabel = (option: EsdtToken) => {
    return (
      <div className='d-flex flex-row align-items-center'>
        <img
          src={option.icon ?? TOKEN_PLACEHOLDER}
          className='xlvg-swap-select-icon p-0 mr-2'
        />
        <span className='xlvg-swap-select__input-title'>
          {option.identifier.split('-')[0]}
        </span>
      </div>
    );
  };
  const selectOption = (esdtToken: SingleValue<EsdtToken>) => {
    if (esdtToken) {
      onSelect(esdtToken);
    }
  };

  const preselectOptionOnLoad = () => {
    if (preselectFirst && options && options.length > 0) {
      selectOption(options[0]);
    }
  };

  React.useEffect(() => {
    preselectOptionOnLoad();
  }, [options]);
  return (
    <Select
      placeholder={'Select token'}
      classNamePrefix={'xlvg-swap-select'}
      className='xlvg-swap-select'
      isLoading={loading}
      options={options}
      styles={selectColourStyles('#082033', '#17153A', '#17153A')}
      formatOptionLabel={(option) => formatOptionLabel(option)}
      getOptionValue={(option) => option.identifier}
      value={optionSelected}
      onChange={selectOption}
    />
  );
};
