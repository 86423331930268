import { useEffect } from 'react';
import BigNumber from 'bignumber.js';
import CurrencyInput from 'react-currency-input-field';
import './AmountInput.scss';

interface AmountInputProps {
  amount: string;
  disabled: boolean;
  placeholder: string;
  min?: string;
  max?: string;
  setAmount: (newVal: string) => void;
  setError?: (error: string | undefined) => void;
}

export const AmountInput = ({
  amount,
  disabled,
  placeholder,
  min,
  max,
  setAmount,
  setError
}: AmountInputProps) => {
  const handleOnChange = (newVal: string | undefined) => {
    if (newVal === amount) {
      return;
    }

    if (newVal === undefined || newVal.length === 0) {
      setAmount('');
      return;
    }

    setAmount(newVal);
  };

  useEffect(() => {
    setError?.(undefined);

    if (max === '0' || min === '0') {
      return;
    }

    console.log({ min, max, amount });
    const amountBig = new BigNumber(amount);
    if (min && amountBig.isLessThanOrEqualTo(min)) {
      setError?.(`The value cannot be less than ${min}`);
      return;
    }

    if (max && amountBig.isGreaterThanOrEqualTo(max)) {
      setError?.(`The value cannot be greater than ${max}`);
      return;
    }
  }, [min, max, amount]);

  return (
    <div className='d-flex flex-column w-100 mt-3'>
      <span className='trade__text flex-grow-1'>{placeholder}</span>
      <div className='d-flex flex-row align-items-center py-2 px-3 w-100 mt-2 amount-with-leverage'>
        <CurrencyInput
          placeholder={placeholder}
          defaultValue={''}
          value={amount}
          min={min}
          max={max}
          allowNegativeValue={false}
          decimalsLimit={6}
          onValueChange={(_value) => handleOnChange(_value)}
          disabled={disabled}
          className='amount-with-leverage--input'
        />
      </div>
    </div>
  );
};
