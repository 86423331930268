import { Transaction } from '@multiversx/sdk-core/out';

export class BorrowAndOpenPositionResponse {
  balance = '';
  feePercentage?: number;
  liquidationPrice?: string;
  transactions: Transaction[] = [];
  exchangeRate?: string;
  amountOut?: string;

  constructor(init?: Partial<BorrowAndOpenPositionResponse>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): BorrowAndOpenPositionResponse {
    const offer = new BorrowAndOpenPositionResponse({
      balance: response.balance,
      feePercentage: response.feePercentage,
      liquidationPrice: response.liquidationPrice,
      transactions: response.transactions as Transaction[],
      exchangeRate: response.exchangeRate,
      amountOut: response.amountOut
    });
    return offer;
  }
}
