import { EsdtToken } from 'types';
import { SwapSelect } from '..';
import './SwapInput.scss';
interface SwapInputProps {
  label?: string;
  className?: string;
  options: EsdtToken[];
  loading: boolean;
  value?: number | string | undefined;
  preselectFirst?: boolean;
  onChangeValue?: (value: number | string | undefined) => void;
  optionSelected: EsdtToken | undefined;
  onSelect: (value: EsdtToken | undefined) => void;
  disableInput?: boolean;
}
export const SwapInput = ({
  className = '',
  label,
  value,
  options,
  loading,
  preselectFirst = false,
  optionSelected,
  onChangeValue,
  onSelect,
  disableInput = false
}: SwapInputProps) => {
  return (
    <div className={className}>
      {label && <div className='swap-input__label'>{label}</div>}
      <div className={'swap-input swap-input__wrapper'}>
        <SwapSelect
          options={options}
          optionSelected={optionSelected}
          loading={loading}
          preselectFirst={preselectFirst}
          onSelect={onSelect}
        />
        <input
          disabled={disableInput}
          type='number'
          value={value}
          onChange={(event) => onChangeValue?.(event.target.value)}
        />
      </div>
    </div>
  );
};
