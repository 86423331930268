import { gql } from '@apollo/client';

export const GET_TRADING_PAIRS = gql`
  query GET_TRADING_PAIRS {
    tradingPairs {
      firstToken {
        identifier
        name
        decimals
        balance
        ticker
        assets {
          pngUrl
        }
      }
      secondToken {
        identifier
        name
        decimals
        balance
        ticker
        assets {
          pngUrl
        }
      }
    }
  }
`;
