import { EsdtToken } from 'types';

export class Offer {
  ids!: number[];
  token!: EsdtToken;
  amount!: string;
  apr!: number;
  maxDuration!: number;

  constructor(init?: Partial<Offer>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): Offer {
    const offer = new Offer({
      ids: response.ids,
      amount: response.amount,
      apr: response.apr,
      maxDuration: response.maxDuration,
      token: EsdtToken.fromResponse(response.token)
    });
    return offer;
  }
}
export enum OfferTypeEnum {
  Lending = 'Lending',
  Borrowing = 'Borrowing'
}
export type OfferType = OfferTypeEnum.Borrowing | OfferTypeEnum.Lending;

export class LendingPosition {
  token!: EsdtToken;
  amount!: string;
  offerId!: string;
  apr!: number;
  endDateTs!: number;
  reward!: PositionReward | null;
  status!: string;

  constructor(init?: Partial<LendingPosition>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): LendingPosition {
    const position = new LendingPosition({
      amount: response.amount,
      offerId: response.offerId,
      apr: response.apr,
      endDateTs: response.endDateTs,
      status: response.status,
      token: EsdtToken.fromResponse(response.token),
      reward: response.reward
        ? PositionReward.fromResponse(response.reward)
        : null
    });
    return position;
  }
}

export class PositionReward {
  positionRewardAmount!: string;
  platformToken!: EsdtToken;

  constructor(init?: Partial<PositionReward>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): PositionReward {
    const positionReward = new PositionReward({
      positionRewardAmount: response.positionRewardAmount,
      platformToken: response.platformToken
        ? EsdtToken.fromResponse(response.platformToken)
        : undefined
    });
    return positionReward;
  }
}

export class BorrowingPosition {
  borrowedAmount!: string;
  offerId!: string;
  collateralAmount!: string;
  token!: EsdtToken;
  endDateTs!: number;
  swappedToken!: SwappedToken | null;
  status!: string;

  constructor(init?: Partial<BorrowingPosition>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): BorrowingPosition {
    const position = new BorrowingPosition({
      borrowedAmount: response.borrowedAmount,
      offerId: response.offerId,
      collateralAmount: response.collateralAmount,
      token: EsdtToken.fromResponse(response.token),
      endDateTs: response.endDateTs,
      swappedToken: response.swappedToken
        ? SwappedToken.fromResponse(response.swappedToken)
        : null,
      status: response.status
    });
    return position;
  }
}

export class SwappedToken {
  currentBorrowedValue!: string;
  token!: EsdtToken;
  amount!: string;
  liquidationPrice!: string;
  takeProfitPrice?: string;
  stopLossPrice?: string;

  constructor(init?: Partial<SwappedToken>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): SwappedToken {
    const swappedToken = new SwappedToken({
      currentBorrowedValue: response.currentBorrowedValue,
      token: EsdtToken.fromResponse(response.token),
      amount: response.amount,
      liquidationPrice: response.liquidationPrice,
      takeProfitPrice: response.takeProfitPrice,
      stopLossPrice: response.stopLossPrice
    });
    return swappedToken;
  }
}

export class TradeDetails {
  exchangeRate!: string;
  amountIn!: string;
  amountOut!: string;
  entryPrice!: string;
  liquidationPrice!: string;

  constructor(init?: Partial<TradeDetails>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): TradeDetails {
    const tradeDetails = new TradeDetails({
      exchangeRate: response.exchangeRate,
      amountIn: response.amountIn,
      amountOut: response.amountOut,
      entryPrice: response.entryPrice,
      liquidationPrice: response.liquidationPrice
    });
    return tradeDetails;
  }
}

export enum PositionStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}
