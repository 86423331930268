import { gql } from '@apollo/client';

export const GET_BORROW_AND_OPEN_POSITION = gql`
  query GET_BORROW_AND_OPEN_POSITIONS($args: BorrowAndOpenPositionArgs!) {
    borrowAndOpenPosition(args: $args) {
      balance
      feePercentage
      liquidationPrice
      amountOut
      exchangeRate
      transactions {
        nonce
        value
        sender
        receiver
        gasPrice
        gasLimit
        data
        chainID
        version
        options
        status
        signature
      }
    }
  }
`;
