import { UserAddress } from 'components/UserAddress/UserAddress';

export const ActivityNavbar = () => {
  return (
    <div className='xlvg-navbar'>
      <div className='d-flex align-items-end'>
        <div className='d-flex flex-column mr-2'>
          <span className='xlvg-navbar__title'>My Activity</span>
          <span className='xlvg-navbar__subtitle'>
            Your perfect tool to lend and
            <br />
            borrow in today’s market
          </span>
        </div>
      </div>
      <UserAddress />
    </div>
  );
};
