import { useMemo } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { Collapse, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatTicker } from 'helpers/formatTicker';
import { AmountInput } from 'pages/Trade/Components/AmountInput/AmountInput';
import { TradeLimit } from 'pages/Trade/context';
import { EsdtToken } from 'types';

interface CheckboxOptionProps {
  text: string;
  info: string;
  tokenIn: EsdtToken | undefined;
  tokenOut: EsdtToken | undefined;
  entryPrice: string | undefined;
  positionSize: string | undefined;
  min?: string;
  max?: string;
  className?: string;
  limit: TradeLimit;
  setTradeLimit: React.Dispatch<React.SetStateAction<TradeLimit>>;
}

const CheckboxOption = ({
  limit,
  setTradeLimit,
  text,
  info,
  tokenIn,
  tokenOut,
  entryPrice,
  positionSize,
  className,
  min,
  max
}: CheckboxOptionProps) => {
  const { pending } = useGetActiveTransactionsStatus();

  const handleCheckboxChange = () => {
    setTradeLimit((prev) => {
      return { ...prev, checked: !prev.checked };
    });
  };

  const pnl = useMemo(() => {
    const amount = limit.amount;
    if (
      !tokenIn ||
      !tokenOut ||
      !positionSize ||
      !entryPrice ||
      amount.length === 0
    ) {
      return;
    }

    const amountWithDecimals = new BigNumber(amount).shiftedBy(
      tokenIn.decimals
    );

    return amountWithDecimals
      .minus(entryPrice)
      .multipliedBy(new BigNumber(positionSize).shiftedBy(-tokenOut.decimals))
      .shiftedBy(-tokenIn.decimals)
      .toFixed(tokenIn.decimals);
  }, [tokenIn, positionSize, entryPrice, limit.amount, tokenOut]);

  const pnlClassName = useMemo(() => {
    if (!pnl) {
      return '';
    }

    return new BigNumber(pnl).isNegative()
      ? 'trade__text--warning'
      : 'trade__text--success';
  }, [pnl]);

  const marketOrderTooltip = <Tooltip id='tooltip'>{info}</Tooltip>;

  return (
    <div className='d-flex flex-column w-100 flex-grow-1 mx-2'>
      <div className={`d-flex align-items-center ${className}`}>
        <input
          type='checkbox'
          checked={limit.checked}
          onChange={handleCheckboxChange}
          className='mr-1'
        />
        <span className='trade__text'>{text}</span>
        <OverlayTrigger placement='top' overlay={marketOrderTooltip}>
          <FontAwesomeIcon className='ml-2' color='white' icon={faInfoCircle} />
        </OverlayTrigger>
      </div>
      <Collapse in={limit.checked}>
        <div>
          <div className='d-flex flex-column'>
            <AmountInput
              placeholder={`Trigger price in ${formatTicker(
                tokenIn?.identifier
              )}`}
              amount={limit.amount}
              disabled={pending}
              setAmount={(newVal: string) => {
                setTradeLimit((prev) => {
                  return { ...prev, amount: newVal };
                });
              }}
              min={min}
              max={max}
              setError={(err: string | undefined) =>
                setTradeLimit((prev) => {
                  return { ...prev, error: err };
                })
              }
            />
            {limit.error ? (
              <span className='trade__text trade__text--warning mt-1'>
                {limit.error}
              </span>
            ) : (
              tokenIn &&
              pnl && (
                <span className='trade__text mt-1'>
                  Estimated PnL:{' '}
                  <span className={`trade__text ${pnlClassName}`}>
                    {pnl} {formatTicker(tokenIn.identifier)}
                  </span>
                </span>
              )
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default CheckboxOption;
