import './SwapRateData.scss';
interface SwapRateDataProps {
  title: string;
  subtitle: string;
}
export const SwapRateData = ({ subtitle, title }: SwapRateDataProps) => {
  return (
    <div className='swap-rate__data'>
      <div className='swap-rate__title'>{title}</div>
      <div className='swap-rate__subtitle'>{subtitle}</div>
    </div>
  );
};
