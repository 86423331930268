import BigNumber from 'bignumber.js';
import { toFixedWithoutRounding } from 'helpers';
import { Offer, OfferType, OfferTypeEnum } from 'types';
import './OfferItem.scss';
import { ReactComponent as OfferApr } from '../../assets/img/OfferApr.svg';
import { ReactComponent as OfferCalendar } from '../../assets/img/OfferCalendar.svg';
interface OfferItemProp {
  offersType: OfferType;
  offer: Offer;
  maxAmount: number;
  onClick?: (offer: Offer) => void;
}
export const OfferItem = ({
  offersType,
  offer,
  onClick = undefined,
  maxAmount
}: OfferItemProp) => {
  const normalizeAmount = new BigNumber(offer.amount)
    .shiftedBy(-offer.token.decimals)
    .toNumber();

  return (
    <div
      className='xlvg-offer__item m-2'
      onClick={() => onClick && onClick(offer)}
    >
      <div
        className={`xlvg-offer__item__overlay xlvg-offer__item__overlay-${
          offersType === OfferTypeEnum.Lending ? 'lending' : 'borrowing'
        }`}
        style={{ width: `${(normalizeAmount * 100) / maxAmount}%` }}
      ></div>
      <div className='xlvg-offer__item-view'>
        <div className='d-flex flex-column align-items-center'>
          <img
            src={offer.token.icon}
            style={{ height: '22px', width: '22px' }}
          />
          <span className='xlvg-offer__text'>
            {toFixedWithoutRounding(normalizeAmount, 4)}{' '}
            {offer.token.identifier.split('-')[0]}
          </span>
        </div>
        <div className='d-flex flex-column align-items-center'>
          <OfferApr />
          <span className='xlvg-offer__text'>{offer.apr}%</span>
        </div>
        <div className='d-flex flex-column align-items-center'>
          <OfferCalendar />
          <span className='xlvg-offer__text'>{offer.maxDuration} DAYS</span>
        </div>
      </div>
    </div>
  );
};
