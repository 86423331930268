import React from 'react';
import { OfferType, OfferTypeEnum } from 'types';
import { ReactComponent as Borrow } from '../../../../assets/img/borrow.svg';
import { ReactComponent as Lend } from '../../../../assets/img/lend.svg';
export const DashboardLendBorrowButtons = ({
  offerType,
  onClick
}: {
  offerType: OfferType;
  onClick: (type: OfferType) => void;
}) => {
  const isLendingSelected = offerType === OfferTypeEnum.Lending;
  return (
    <div className='xlvg-dashboard__lend-borrow-picker'>
      <div
        className={`button${isLendingSelected ? ' active' : ''}`}
        onClick={() => onClick(OfferTypeEnum.Lending)}
      >
        <div className='text'>Lend</div>
        <Lend className='icon' />
      </div>
      <div
        className={`button${!isLendingSelected ? ' active' : ''}`}
        onClick={() => onClick(OfferTypeEnum.Borrowing)}
      >
        <div className='text'>Borrow</div>
        <Borrow className='icon' />
      </div>
    </div>
  );
};
