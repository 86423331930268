import React, { useState } from 'react';
import { PositionStatus } from 'types';
import { ActivityContext } from './activity-context';

export const ActivityContextProvider = ({ children }: { children: any }) => {
  const [lendingFilter, setLendingFilter] = useState<
    PositionStatus.ALL | PositionStatus.ACTIVE | PositionStatus.INACTIVE
  >(PositionStatus.ALL);
  const [borrowingFilter, setBorrowingFilter] = useState<
    PositionStatus.ALL | PositionStatus.ACTIVE | PositionStatus.INACTIVE
  >(PositionStatus.ALL);

  const contextValue = {
    lendingFilter,
    setLendingFilter,
    borrowingFilter,
    setBorrowingFilter
  };

  return (
    <ActivityContext.Provider value={contextValue}>
      {children}
    </ActivityContext.Provider>
  );
};
