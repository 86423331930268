import { gql } from '@apollo/client';

export const GET_TOKENS = gql`
  query GET_TOKENS {
    tokens: whitelistedTokens {
      identifier
      name
      decimals
      balance
      ticker
      assets {
        pngUrl
      }
    }
  }
`;

export const ACCOUNT_TOKENS = gql`
  query ACCOUNT_TOKENS {
    tokens: accountTokens {
      identifier
      name
      owner
      ticker
      balance
      circulatingSupply
      assets {
        pngUrl
      }
      decimals
    }
  }
`;

export const PAIR_TOKENS = gql`
  query PAIR_TOKENS($tokenId: String!) {
    tokens: pairTokens(tokenId: $tokenId) {
      identifier
      name
      owner
      ticker
      assets {
        pngUrl
      }
      balance
      decimals
    }
  }
`;
