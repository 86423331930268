import { UserAddress } from 'components';
import { TokenSelect } from 'components/Select/TokenSelect';

export const HomeNavbar = () => {
  return (
    <div className='xlvg-navbar d-flex flex-column flex-lg-row align-items-start align-items-lg-end px-3 px-lg-0'>
      <div className='d-flex flex-column flex-lg-row align-items-end'>
        <div className='d-flex flex-column mr-2 align-self-start'>
          <span className='xlvg-navbar__title'>Choose token</span>
          <span className='xlvg-navbar__subtitle'>
            Your perfect tool to lend and <br /> borrow in today's market
          </span>
        </div>
        <TokenSelect className='mt-3 mt-lg-0' />
      </div>
      <UserAddress className='mt-3 mt-lg-0' />
    </div>
  );
};
