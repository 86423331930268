import React from 'react';
import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client';
import { GET_BORROWING_POSITIONS } from 'api/query';
import { BorrowingPosition } from 'types';

const useGetBorrowingPositions = (): {
  data: BorrowingPosition[];
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => Promise<ApolloQueryResult<any>>;
} => {
  const { data, loading, error, refetch } = useQuery(GET_BORROWING_POSITIONS);

  return React.useMemo(() => {
    return {
      data: data?.borrowingPositions.map(BorrowingPosition.fromResponse) || [],
      loading,
      error,
      refetch
    };
  }, [data, loading, error]);
};

export { useGetBorrowingPositions };
