import React from 'react';
import './OffersView.scss';
import { useHomeContextConfig } from 'pages/Home/context';
import { OfferTypeEnum } from 'types';
import { Offers } from '../Offers';
export const OffersView = () => {
  const { selectedToken } = useHomeContextConfig();
  return (
    <div className='xlvg-offers__view'>
      <Offers
        tokenId={selectedToken?.identifier}
        className='xlvg-offers__items'
        offersType={OfferTypeEnum.Lending}
      />
      <Offers
        tokenId={selectedToken?.identifier}
        className='xlvg-offers__items'
        offersType={OfferTypeEnum.Borrowing}
      />
    </div>
  );
};
