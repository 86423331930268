import React, { useEffect } from 'react';
import { useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import { Loader } from '@multiversx/sdk-dapp/UI/Loader/Loader';
import BigNumber from 'bignumber.js';
import { useGetOffers } from 'hooks/useGetOffers';
import { useHomeContextConfig } from 'pages/Home/context';
import { EsdtToken, Offer, OfferType } from 'types';
import { OfferItem } from '../../../../components/OfferItem';
import './Offers.scss';
interface OffersProp {
  offersType: OfferType;
  className?: string;
  tokenId?: EsdtToken['identifier'];
}
export const Offers = ({
  offersType,
  className = undefined,
  tokenId = undefined
}: OffersProp) => {
  const { data, loading, error, refetch } = useGetOffers(offersType, tokenId);
  const { setApr, setPeriod, setTokenAmount } = useHomeContextConfig();
  const { success } = useGetActiveTransactionsStatus();

  useEffect(() => {
    if (success) {
      refetch();
    }
  }, [success]);

  if (loading) {
    return <Loader noText />;
  }

  if (error) {
    <span className='text-error'>{error.message}</span>;
  }

  const onClick = (offer: Offer) => {
    setApr(offer.apr.toString());
    setPeriod(offer.maxDuration.toString());
    setTokenAmount(
      new BigNumber(offer.amount)
        .shiftedBy(-offer.token.decimals)
        .toNumber()
        .toString()
    );
  };

  const amounts = data.map(({ amount, token }) =>
    new BigNumber(amount).shiftedBy(-token.decimals).toNumber()
  );

  const maxAmount = Math.max(...amounts);

  return (
    <div
      className={'xlvg-offers__items-view' + (className ? ` ${className}` : '')}
    >
      <div className='xlvg-offers__title my-4'>{`${offersType} orders`}</div>
      {data?.length > 0 ? (
        data?.map((offer, index) => (
          <OfferItem
            key={`${offersType}_${index}`}
            offer={offer}
            onClick={onClick}
            maxAmount={maxAmount + (maxAmount * 10) / 100}
            offersType={offersType}
          />
        ))
      ) : (
        <div className='xlvg-offers__title my-4'>No {offersType} offers</div>
      )}
    </div>
  );
};
