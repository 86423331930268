import { store } from '@multiversx/sdk-dapp/reduxStore/store';
import { Provider } from 'react-redux';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { DappProvider, Layout, UpdateNotification } from 'components';
import {
  TransactionsToastList,
  NotificationModal,
  SignTransactionsModals
} from 'components';
import { ApolloAuthenticatedWrapper } from 'components/ApolloAuthenticatedWrapper';
import { apiTimeout, walletConnectV2ProjectId } from 'config';
import { PageNotFound } from 'pages';
import { routes } from 'routes';
import { EnvironmentsEnum } from 'types';

export const App = () => {
  return (
    <>
      <UpdateNotification />
      <Router>
        <DappProvider
          environment={EnvironmentsEnum.devnet}
          customNetworkConfig={{
            name: 'customConfig',
            apiTimeout,
            walletConnectV2ProjectId
          }}
        >
          <Provider store={store}>
            <Layout>
              <TransactionsToastList />
              <NotificationModal />
              <SignTransactionsModals className='custom-class-for-modals' />
              <ApolloAuthenticatedWrapper>
                <Routes>
                  {routes.map((route, index) => (
                    <Route
                      path={route.path}
                      key={'route-key-' + index}
                      element={<route.component />}
                    />
                  ))}
                  <Route path='*' element={<PageNotFound />} />
                </Routes>
              </ApolloAuthenticatedWrapper>
            </Layout>
          </Provider>
        </DappProvider>
      </Router>
    </>
  );
};
