import React, { useEffect, useState } from 'react';
import './Sidebar.scss';
import { useReactiveVar } from '@apollo/client';
import { WithClassnameType } from '@multiversx/sdk-dapp/UI/types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Switch from 'react-switch';
import { Dashboard, Logout, MyActivity, SwapNavbar } from 'assets/img';
import { logout } from 'helpers';
import { xlevProVar } from 'helpers/reactive';
import { useGetAccount } from 'hooks';
import { routeNames } from 'routes';
import Logo from '../../assets/img/Logo.png';
import { useIsMobile } from 'hooks/useIsMobile';
import InfoModal from 'components/InfoModal/InfoModal';

interface SidebarProps extends WithClassnameType {
  forwardedRef: React.RefObject<HTMLDivElement>;
}

const Sidebar = ({ forwardedRef, className }: SidebarProps) => {
  const location = useLocation();
  const { address } = useGetAccount();
  const isLoggedIn = Boolean(address);

  const navigate = useNavigate();

  const isMobile = useIsMobile();

  const xlevPro = useReactiveVar(xlevProVar);

  const [showInfoModal, setShowInfoModal] = useState(false);

  const handleProModeChange = (checked: boolean) => {
    if (checked && isMobile) {
      setShowInfoModal(true);
      return;
    }

    xlevProVar(checked);
  };

  useEffect(() => {
    if (xlevPro) {
      navigate('/');
      return;
    }

    navigate('/trade');
  }, [xlevPro]);

  const handleLogout = () => {
    logout(location.pathname);
  };
  return (
    <aside className={`sidebar ${className || ''}`} ref={forwardedRef}>
      <div>
        <div className='logo'>
          <img src={Logo} alt='xLeverage' />
        </div>
        <nav className='sidebar-content'>
          <ul>
            {xlevPro && (
              <li
                className={
                  location.pathname === routeNames.home ? 'active' : ''
                }
              >
                <Dashboard className='mr-2' />
                <Link to={routeNames.home}>Lending/Borrowing</Link>
              </li>
            )}
            {!xlevPro && (
              <li
                className={
                  location.pathname === routeNames.trade ? 'active' : ''
                }
              >
                <SwapNavbar className='mr-2' />
                <Link to={routeNames.trade}>Trade</Link>
              </li>
            )}
            <li
              className={
                location.pathname === routeNames.activity ? 'active' : ''
              }
            >
              <MyActivity className='mr-2' />
              <Link to={routeNames.activity}>MyActivity</Link>
            </li>
            {xlevPro && (
              <li
                className={
                  location.pathname === routeNames.swap ? 'active' : ''
                }
              >
                <SwapNavbar className='mr-2' />
                <Link to={routeNames.swap}>Swap</Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
      <div className='d-flex flex-row align-items-center mt-auto pro'>
        <span className='mr-2'>xLeverage Pro</span>{' '}
        <Switch
          onChange={handleProModeChange}
          checked={xlevPro}
          checkedIcon={false}
          uncheckedIcon={false}
        />
      </div>
      {isLoggedIn && (
        <div className='logout'>
          <Logout className='mr-2' />
          <a onClick={handleLogout}>Logout</a>
        </div>
      )}
      <InfoModal
        title={'xLeverage Pro'}
        subtitle={
          'At the moment, this feature is not available on mobile devices'
        }
        show={showInfoModal}
        onHide={() => setShowInfoModal(false)}
      />
    </aside>
  );
};

export default Sidebar;
