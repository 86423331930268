import { TradeContextProvider } from './context';
import { TradeContent } from './TradeContent';

export const TradePage = () => {
  return (
    <div className='d-flex flex-fill align-items-center container'>
      <div className='row w-100 no-gutters'>
        <div className='col-12 col-md-8 col-lg-6 mx-auto'>
          <TradeContextProvider>
            <TradeContent />
          </TradeContextProvider>
        </div>
      </div>
    </div>
  );
};
