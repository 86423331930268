import { ToggleButton } from 'react-bootstrap';
import { TradeType } from '../../Models';

interface TradeToggleButtonProps {
  value: TradeType;
  shortButtonEnabled: boolean;
  onValueChanged: (value: TradeType) => void;
}

export const TradeToggleButton = ({
  value,
  shortButtonEnabled,
  onValueChanged
}: TradeToggleButtonProps) => {
  const onChange = (newValue: TradeType) => {
    onValueChanged(newValue);
  };

  return (
    <div className='d-flex flex-row w-100'>
      <button
        className={`xlvg-button xlvg-button--${
          value === TradeType.LONG ? 'green' : 'inactive'
        } xlvg-button--left-borders w-50`}
        onClick={() => onChange(TradeType.LONG)}
      >
        Buy/Long
      </button>
      <button
        className={`xlvg-button xlvg-button--${
          value === TradeType.SHORT ? 'red' : 'inactive'
        } xlvg-button--right-borders w-50`}
        onClick={() => onChange(TradeType.SHORT)}
        disabled={!shortButtonEnabled}
      >
        Sell/Short
      </button>
    </div>
  );
};

export default ToggleButton;
