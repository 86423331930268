import { gql } from '@apollo/client';

export const CLAIM = gql`
  mutation CLAIM($tokenId: String!) {
    claim(tokenId: $tokenId) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
      status
      signature
    }
  }
`;
