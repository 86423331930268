import React from 'react';
import { EsdtToken } from 'types';
import { HomeContext } from './home-context';

export const HomeContextProvider = ({ children }: { children: any }) => {
  const [selectedToken, setSelectedToken] = React.useState<
    EsdtToken | undefined
  >(undefined);
  const [tokenAmount, setTokenAmount] = React.useState<string>('');
  const [period, setPeriod] = React.useState<string>('');
  const [apr, setApr] = React.useState<string>('');

  return (
    <HomeContext.Provider
      value={{
        selectedToken,
        setSelectedToken,
        tokenAmount,
        setTokenAmount,
        period,
        setPeriod,
        apr,
        setApr
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};
