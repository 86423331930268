import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import BigNumber from 'bignumber.js';
import { Spinner } from 'react-bootstrap';
import { OPEN_POSITION } from 'api/mutations/positions';
import { sendAndSignTransactions } from 'api/transaction';
import { SwapIcon } from 'assets/img';
import './SwapCard.scss';
import ActionOrConnect from 'components/ActionOrConnect';
import TakeProfitStopLoss from 'components/TakeProfitStopLoss/TakeProfitStopLoss';
import { toFixedWithoutRounding } from 'helpers';
import {
  useGetActiveTransactionsStatus,
  useGetPairTokens,
  useGetTokens
} from 'hooks';
import { useGetTradeDetails } from 'hooks/useGetTradeDetails';
import { useTradeContext } from 'pages/Trade/context';
import { EsdtToken } from 'types';
import { SwapInput, SwapRateData } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';

export const SwapCard = () => {
  const { data: tokensIn, loading: loadingTokensIn } = useGetTokens();
  const [tokenIn, setTokenIn] = useState<EsdtToken | undefined>(undefined);

  const { data: tokensOut, loading: loadingTokensOut } = useGetPairTokens(
    tokenIn ? tokenIn.identifier : undefined
  );

  const { pending, success } = useGetActiveTransactionsStatus();

  const [tokenOut, setTokenOut] = useState<EsdtToken | undefined>(undefined);

  const { data: tradeDetails, loading: loadingTradeDetails } =
    useGetTradeDetails(
      tokenIn ? tokenIn.identifier : undefined,
      tokenOut ? tokenOut.identifier : undefined
    );

  const [
    performOpenPosition,
    {
      data: openPositionDate,
      loading: openPositionLoading,
      error: openPositionError
    }
  ] = useMutation(OPEN_POSITION, { errorPolicy: 'all' });

  const { stopLossLimit, takeProfitLimit, resetLimits, limitsError } =
    useTradeContext();

  const hasTradeDetails = useMemo(() => {
    return (
      tradeDetails &&
      tradeDetails.amountIn !== '0' &&
      tradeDetails.amountOut !== '0'
    );
  }, [tradeDetails]);

  useEffect(() => {
    if (success) {
      resetLimits();
    }
  }, [success]);

  useEffect(() => {
    resetLimits();
  }, [tokenIn, tokenOut]);

  const handleSign = () => {
    if (!tokenIn || !tokenOut) return;

    const takeProfitAmount =
      takeProfitLimit.amount.length === 0
        ? undefined
        : new BigNumber(takeProfitLimit.amount)
            .shiftedBy(tokenIn.decimals)
            .toFixed();

    const stopLossAmount =
      stopLossLimit.amount.length === 0
        ? undefined
        : new BigNumber(stopLossLimit.amount)
            .shiftedBy(tokenIn.decimals)
            .toFixed();

    const args = {
      tokenIn: tokenIn.identifier,
      tokenOut: tokenOut.identifier,
      takeProfitAmount: takeProfitAmount,
      stopLossAmount: stopLossAmount
    };

    performOpenPosition({
      variables: {
        args
      }
    });
  };

  const denominatedTokenOut =
    tokenOut && tradeDetails
      ? toFixedWithoutRounding(
          new BigNumber(tradeDetails.amountOut)
            .shiftedBy(-tokenOut.decimals)
            .toNumber(),
          6
        )
      : undefined;

  const denominatedTokenIn =
    tokenIn && tradeDetails
      ? toFixedWithoutRounding(
          new BigNumber(tradeDetails.amountIn)
            .shiftedBy(-tokenIn.decimals)
            .toNumber(),
          6
        )
      : undefined;

  const denominateExchangeRate =
    tokenOut && tradeDetails
      ? toFixedWithoutRounding(
          new BigNumber(tradeDetails.exchangeRate)
            .shiftedBy(-tokenOut.decimals)
            .toNumber(),
          4
        )
      : undefined;

  const loading =
    pending ||
    loadingTokensIn ||
    loadingTokensOut ||
    loadingTradeDetails ||
    openPositionLoading;

  React.useEffect(() => {
    if (openPositionDate && openPositionDate.openPosition) {
      const transactionDisplayInfoType = {
        processingMessage: 'Open position',
        errorMessage: 'An error has occurred while opening the position',
        successMessage: 'The position has been opened successfully'
      };
      sendAndSignTransactions(
        openPositionDate.openPosition,
        transactionDisplayInfoType
      );
    }
  }, [openPositionDate?.openPosition]);

  return (
    <div className='swap-card__radius swap-card__background'>
      <div className='p-4'>
        <div className='d-flex flex-column align-items-center'>
          <div className='swap-card-text__size-normal swap-card-text__white mb-4'>
            Swap
          </div>
          <div className='w-100 my-4'>
            <div className='d-flex justify-content-around'>
              <SwapRateData
                title={'1'}
                subtitle={tokenIn ? tokenIn.ticker : '-'}
              />
              <SwapIcon />
              <SwapRateData
                title={denominateExchangeRate ?? '-'}
                subtitle={tokenOut ? tokenOut.ticker : '-'}
              />
            </div>
          </div>
          <SwapInput
            className='w-100 mt-4'
            label='Swap from'
            options={tokensIn}
            preselectFirst
            onSelect={(value: EsdtToken | undefined) => setTokenIn(value)}
            optionSelected={tokenIn}
            loading={loadingTokensIn}
            value={
              tradeDetails && denominatedTokenIn
                ? denominatedTokenIn
                : undefined
            }
            disableInput
          />
          <SwapInput
            className='w-100 my-4'
            label='Swap to'
            preselectFirst
            options={tokensOut}
            onSelect={(value: EsdtToken | undefined) => setTokenOut(value)}
            optionSelected={tokenOut}
            loading={loadingTokensOut}
            value={
              tradeDetails && denominatedTokenOut
                ? denominatedTokenOut
                : undefined
            }
            disableInput
          />
          {hasTradeDetails && (
            <TakeProfitStopLoss
              tokenIn={tokenIn}
              tokenOut={tokenOut}
              entryPrice={tradeDetails?.entryPrice}
              positionSize={tradeDetails?.amountOut}
              liquidationPrice={tradeDetails?.liquidationPrice}
            />
          )}
          <ActionOrConnect className='xlvg-button xlvg-button-connect '>
            <button
              className='xlvg-button xlvg-button-radius-max mt-4 mb-2'
              onClick={handleSign}
              disabled={
                loading ||
                tokenIn === undefined ||
                tokenOut === undefined ||
                !hasTradeDetails ||
                limitsError !== undefined
              }
            >
              {loading ? (
                <Spinner as='span' animation='border' size='sm' />
              ) : (
                'Swap'
              )}
            </button>
          </ActionOrConnect>
          {openPositionError && (
            <span className='trade__text trade__text--warning mt-3'>
              <FontAwesomeIcon className='mr-2' icon={faWarning} />
              {openPositionError.message}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
