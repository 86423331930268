import React from 'react';
import { WithClassnameType } from '@multiversx/sdk-dapp/UI/types';
import { CircleGreen } from 'assets/img';
import ActionOrConnect from 'components/ActionOrConnect';
import { useGetAccountAddress, useGetNetworkConfig } from 'hooks';
import './UserAddress.scss';

export const UserAddress = ({ className }: WithClassnameType) => {
  const { address } = useGetAccountAddress();
  const { network } = useGetNetworkConfig();

  const shortAddress = `${address.slice(0, 7)}...${address.slice(
    address.length - 4,
    address.length
  )}`;

  return (
    <ActionOrConnect
      className={`xlvg-button xlvg-button-connect address__connect ${
        className || ''
      }`}
    >
      <div className={`address-container ${className || ''}`}>
        <CircleGreen />
        <a
          target='_blank'
          href={`${network.explorerAddress}/address/${address}`}
        >
          <span className='address__text'>{shortAddress}</span>
        </a>
      </div>
    </ActionOrConnect>
  );
};
