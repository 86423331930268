import React from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { ACCOUNT_TOKENS } from 'api/query';
import { EsdtToken } from 'types';

const useGetAccountTokens = (): {
  data: EsdtToken[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const { data, loading, error, startPolling, stopPolling } =
    useQuery(ACCOUNT_TOKENS);

  return React.useMemo(() => {
    return {
      data: data?.tokens.map(EsdtToken.fromResponse),
      loading,
      error,
      startPolling,
      stopPolling
    };
  }, [data, loading, error]);
};

export { useGetAccountTokens };
