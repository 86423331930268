import colors from 'assets/sass/variables.module.scss';

export const selectColourStyles = (
  bgColor = colors.colorPrimary,
  selectedBgColor = colors.colorPrimaryDark,
  hoverColor = colors.colorPrimaryDark
) => {
  return {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? selectedBgColor : bgColor,
      '&:hover': {
        backgroundColor: hoverColor,
        cursor: 'pointer'
      }
    })
  };
};
