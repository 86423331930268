import { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { impersonateVar, useGetSecurityAdmins } from 'hooks';
import './Impersonate.scss';

export const Impersonate = () => {
  const { address } = useGetAccount();
  const { data } = useGetSecurityAdmins();

  const impersonateAddress = useReactiveVar(impersonateVar);

  const isAdmin = useMemo(() => {
    return data?.includes(address) ?? false;
  }, [address, data]);

  const handleOnChange = (event: any) => {
    const value = event.target.value;
    impersonateVar(value);
  };

  if (!isAdmin) {
    return null;
  }

  return (
    <input
      className='impersonate-input'
      placeholder='Impersonate address'
      type='text'
      value={impersonateAddress}
      onChange={handleOnChange}
    />
  );
};
