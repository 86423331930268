import React from 'react';
import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client';
import { GET_LENDING_POSITIONS } from 'api/query';
import { LendingPosition } from 'types';

const useGetLendingPositions = (): {
  data: LendingPosition[];
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => Promise<ApolloQueryResult<any>>;
} => {
  const { data, loading, error, refetch } = useQuery(GET_LENDING_POSITIONS);

  return React.useMemo(() => {
    return {
      data: data?.lendingPositions.map(LendingPosition.fromResponse) || [],
      loading,
      error,
      refetch
    };
  }, [data, loading, error]);
};

export { useGetLendingPositions };
