import { useEffect } from 'react';
import { Loader } from '@multiversx/sdk-dapp/UI';
import { useGetActiveTransactionsStatus, useGetLendingPositions } from 'hooks';
import { ActivityContextProvider } from 'pages/Activity/Context';
import '../ActivityCard.scss';
import { useActivityContext } from 'pages/Activity/Context/activity-context';
import { PositionStatus } from 'types';
import { ActivityFilters } from '../ActivityFilters';
import { ActivityItemLending } from '../ActivityItem';

export const LendingActivity = () => {
  const { data, loading, refetch } = useGetLendingPositions();
  const { lendingFilter } = useActivityContext();

  const { success } = useGetActiveTransactionsStatus();

  useEffect(() => {
    if (success) {
      refetch();
    }
  }, [success]);

  return (
    <div className='activity-card'>
      <span className='activity__title'>Lending</span>
      <ActivityFilters type={'lending'} />
      {loading ? (
        <Loader noText />
      ) : (
        data
          .filter(({ status }) =>
            lendingFilter === PositionStatus.ALL
              ? true
              : status === lendingFilter
          )
          .map((lendingPosition, index) => (
            <ActivityItemLending
              key={`lending_${index}`}
              item={lendingPosition}
            />
          ))
      )}
    </div>
  );
};

export const LendingActivityWrapper = () => {
  return (
    <ActivityContextProvider>
      <LendingActivity />
    </ActivityContextProvider>
  );
};
