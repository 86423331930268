import { gql } from '@apollo/client';

export const CREATE_OFFER = gql`
  mutation CREATE_OFFER($args: CreateOfferArgs!) {
    createOffer(args: $args) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
      status
      signature
    }
  }
`;

export const CANCEL_OFFER = gql`
  mutation CANCEL_OFFER($offerId: Float!, $offerType: String!) {
    action: cancelOffer(offerId: $offerId, offerType: $offerType) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
      status
      signature
    }
  }
`;

export const CLOSE_OFFER = gql`
  mutation CLOSE_OFFER($tokenId: String!) {
    action: closeOffer(tokenId: $tokenId) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
      status
      signature
    }
  }
`;
