import { ReactElement } from 'react';
import './ActivityItem.scss';

export interface ActivityDataProps {
  icon: ReactElement;
  title?: string;
  titleElement?: ReactElement;
  subtitle?: string;
  description?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const ActivityData = ({
  icon,
  title,
  titleElement,
  subtitle,
  description,
  disabled,
  onClick
}: ActivityDataProps) => {
  const clickable = onClick !== undefined;

  const subtitleVisible = false;

  const handleOnClick = () => {
    if (disabled) {
      return;
    }

    onClick?.();
  };

  return (
    <div
      className={`activity-item__data ${
        clickable && !disabled ? 'activity-item__data--clickable' : ''
      } ${disabled ? 'activity-item__data--disabled' : ''}`}
      onClick={handleOnClick}
    >
      {icon}
      {description && (
        <span className='activity-item__description'>{description}</span>
      )}
      {title && <span className='activity-item__title'>{title}</span>}
      {titleElement}
      {subtitleVisible && subtitle && (
        <span className='activity-item__subtitle'>{subtitle}</span>
      )}
    </div>
  );
};
