import { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Address } from '@multiversx/sdk-core/out';
import makeVarPersisted from './makeVarPersisted';
import { useGetAccount } from './sdkDappHooks';

export const impersonateVar = makeVarPersisted<string>(
  '',
  'impersonateAddressVar'
);

const useGetAccountAddress = (): {
  address: string;
  initialAddress: string;
} => {
  const { address } = useGetAccount();
  const impersonateAddress = useReactiveVar(impersonateVar);

  const computedAddress = useMemo(() => {
    if (impersonateAddress.length === 0) {
      return address;
    }

    try {
      const newAddress = new Address(impersonateAddress);
      return newAddress.bech32();
    } catch {
      return address;
    }
  }, [impersonateAddress, address]);

  return { address: computedAddress, initialAddress: address };
};

export { useGetAccountAddress };
