import * as React from 'react';
import { DashboardView, HomeNavbar } from './components';
import { OffersView } from './components/OffersView';
import { HomeContextProvider } from './context/HomeContextProvider';

const HomePage = () => {
  return (
    <div className='d-flex flex-column flex-fill container-fluid px-0 overflow-hidden'>
      <HomeNavbar />
      <DashboardView />
      <OffersView />
    </div>
  );
};
export const Home = () => {
  return (
    <HomeContextProvider>
      <HomePage />
    </HomeContextProvider>
  );
};
