import React from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { PAIR_TOKENS } from 'api/query';
import { EsdtToken } from 'types';

const useGetPairTokens = (
  tokenIdentifier: EsdtToken['identifier'] | undefined
): {
  data: EsdtToken[];
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const { data, loading, error, startPolling, stopPolling } = useQuery(
    PAIR_TOKENS,
    {
      variables: {
        tokenId: tokenIdentifier
      },
      skip: tokenIdentifier === undefined
    }
  );

  return React.useMemo(() => {
    return {
      data: data?.tokens.map(EsdtToken.fromResponse) || [],
      loading,
      error,
      startPolling,
      stopPolling
    };
  }, [data, loading, error]);
};

export { useGetPairTokens };
