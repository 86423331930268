import { dAppName } from 'config';
import { ActivityPage } from 'pages/Activity';
import { SwapPage } from 'pages/Swap';
import { TradePage } from 'pages/Trade/Trade';
import { RouteType } from 'types';
import { withPageTitle } from './components/PageTitle';
import { Home } from './pages';

export const routeNames = {
  home: '/',
  unlock: '/unlock',
  activity: '/activity',
  swap: '/swap',
  trade: '/trade'
};

interface RouteWithTitleType extends RouteType {
  title: string;
}

export const routes: RouteWithTitleType[] = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Home
  },
  {
    path: routeNames.activity,
    title: 'My Activity',
    component: ActivityPage
  },
  {
    path: routeNames.swap,
    title: 'Swap',
    component: SwapPage
  },
  {
    path: routeNames.trade,
    title: 'Trade',
    component: TradePage
  }
];

export const mappedRoutes = routes.map((route) => {
  const title = route.title
    ? `${route.title} • xLeverage ${dAppName}`
    : `xLeverage ${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});
