import React from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { GET_TOKENS } from 'api/query';
import { EsdtToken } from 'types';

export const POLLING_INTERVAL = 30000;

const useGetTokens = (): {
  data: EsdtToken[];
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const { data, loading, error, startPolling, stopPolling } =
    useQuery(GET_TOKENS);

  return React.useMemo(() => {
    return {
      data: data?.tokens.map(EsdtToken.fromResponse) || [],
      loading,
      error,
      startPolling,
      stopPolling
    };
  }, [data, loading, error]);
};

export { useGetTokens };
