import { useActivityContext } from 'pages/Activity/Context/activity-context';
import { PositionStatus } from 'types';
import { ActivityFilter } from './ActivityFilter';
import './ActivityFilters.scss';
interface ActivityFiltersProps {
  type: 'lending' | 'borrowing';
}
export const ActivityFilters = ({ type }: ActivityFiltersProps) => {
  const context = useActivityContext();
  const filter =
    context[type === 'lending' ? 'lendingFilter' : 'borrowingFilter'];
  const setFilter =
    context[type === 'lending' ? 'setLendingFilter' : 'setBorrowingFilter'];

  return (
    <div className='activity-filters'>
      <span className='activity-filters__title'>Filters:</span>
      <ActivityFilter
        title={'all'}
        selected={filter === PositionStatus.ALL}
        showCircleIcon={false}
        onFilterSelected={() => setFilter(PositionStatus.ALL)}
      />
      <ActivityFilter
        title={'active'}
        selected={filter === PositionStatus.ACTIVE}
        showCircleIcon={true}
        onFilterSelected={() => setFilter(PositionStatus.ACTIVE)}
      />
      <ActivityFilter
        title={'inactive'}
        selected={filter === PositionStatus.INACTIVE}
        showCircleIcon={true}
        onFilterSelected={() => setFilter(PositionStatus.INACTIVE)}
      />
    </div>
  );
};
