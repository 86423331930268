import { SwapNavbar, SwapWrapper } from './components';

export const SwapPage = () => {
  return (
    <div className='d-flex flex-column flex-fill container-fluid overflow-hidden'>
      <SwapNavbar />
      <SwapWrapper />
    </div>
  );
};
