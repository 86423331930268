import React, { useContext } from 'react';
import { TradeLimit } from './TradeContextProvider';

export interface TradeContextModel {
  takeProfitLimit: TradeLimit;
  setTakeProfitLimit: React.Dispatch<React.SetStateAction<TradeLimit>>;
  stopLossLimit: TradeLimit;
  setStopLossLimit: React.Dispatch<React.SetStateAction<TradeLimit>>;
  resetLimits: () => void;
  limitsError: string | undefined;
}

export const TradeContext = React.createContext<TradeContextModel | null>(null);

TradeContext.displayName = 'TradeContext';

export function useTradeContext(): TradeContextModel {
  const context = useContext(TradeContext);
  if (context == null) {
    throw new Error('TradeContext is null');
  }

  return context;
}
