import { TOKEN_PLACEHOLDER } from 'config';

export class EsdtToken {
  identifier = '';
  ticker = '';
  name = '';
  decimals = 0;
  balance = '0';
  supply = '0';
  icon = '';

  constructor(init?: Partial<EsdtToken>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): EsdtToken {
    const token = new EsdtToken({
      identifier: response.identifier,
      ticker: response.ticker,
      name: response.name,
      decimals: response.decimals,
      balance: response.balance ?? '0',
      supply: response.supply ?? '0',
      icon:
        response.assets?.pngUrl ?? response.assets?.svgUrl ?? TOKEN_PLACEHOLDER
    });
    return token;
  }
}
