import React from 'react';
import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client';
import { CLAIMABLE_REWARDS } from 'api/query';
import { EsdtToken } from 'types';

const useGetClaimableRewards = (): {
  data: EsdtToken[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => Promise<ApolloQueryResult<any>>;
} => {
  const { data, loading, error, refetch } = useQuery(CLAIMABLE_REWARDS);

  React.useEffect(() => {
    if (error) {
      console.error(error.message);
    }
  }, [error]);

  return React.useMemo(() => {
    return {
      data: data?.claimableRewards.map(EsdtToken.fromResponse) || [],
      loading,
      error,
      refetch
    };
  }, [data, loading, error]);
};

export { useGetClaimableRewards };
