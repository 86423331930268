import React from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { TRADE_DETAILS } from 'api/query';
import { EsdtToken, TradeDetails } from 'types';

const POLL_INTERVAL = 30000;

const useGetTradeDetails = (
  tokenInIdentifier: EsdtToken['identifier'] | undefined,
  tokenOutIdentifier: EsdtToken['identifier'] | undefined
): {
  data: TradeDetails | undefined;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const { data, loading, error, startPolling, stopPolling } = useQuery(
    TRADE_DETAILS,
    {
      pollInterval: POLL_INTERVAL,
      variables: {
        tokenIn: tokenInIdentifier,
        tokenOut: tokenOutIdentifier
      },
      skip: tokenInIdentifier === undefined || tokenOutIdentifier === undefined,
      fetchPolicy: 'no-cache'
    }
  );

  return React.useMemo(() => {
    return {
      data: data ? TradeDetails.fromResponse(data.tradeDetails) : undefined,
      loading,
      error,
      startPolling,
      stopPolling
    };
  }, [data, loading, error]);
};

export { useGetTradeDetails };
