import './ClaimableReward.scss';
import React from 'react';
import { useMutation } from '@apollo/client';
import { useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { Spinner } from 'react-bootstrap';
import { CLAIM } from 'api/mutations';
import { sendAndSignTransactions } from 'api/transaction';
import { toFixedWithoutRounding } from 'helpers';
import { EsdtToken } from 'types';

interface ClaimableRewardProps {
  item: EsdtToken;
}
export const ClaimableReward = ({ item }: ClaimableRewardProps) => {
  const [performAction, { data, loading: loadingClaim }] = useMutation(CLAIM);

  const { pending } = useGetActiveTransactionsStatus();

  const loading = loadingClaim || pending;

  const nominatedAmount = toFixedWithoutRounding(
    new BigNumber(item.balance).shiftedBy(-item.decimals).toNumber(),
    4
  );
  const handleOnClick = () => {
    performAction({
      variables: {
        tokenId: item.identifier
      }
    });
  };

  React.useEffect(() => {
    if (data && data.claim) {
      const transactionDisplayInfoType = {
        processingMessage: 'Claim the reward',
        errorMessage: 'An error has occured while claiming the reward',
        successMessage: 'The reward has been claimed succesfully'
      };
      sendAndSignTransactions(data.claim, transactionDisplayInfoType);
    }
  }, [data?.claim]);

  return (
    <div className='claimable-reward-item'>
      <img className='claimable-reward-item__icon' src={item.icon} />
      <div className='claimable-reward-item__info ml-4'>
        {nominatedAmount} {item.ticker}
      </div>
      <div className='claimable-reward-item__button ml-4'>
        <button
          className='xlvg-button xlvg-button-small'
          onClick={handleOnClick}
          disabled={loading}
        >
          {loading ? (
            <Spinner as='span' animation='border' size='sm' />
          ) : (
            'Claim'
          )}
        </button>
      </div>
    </div>
  );
};
