import { gql } from '@apollo/client';

export const CLAIMABLE_REWARDS = gql`
  query CLAIMABLE_REWARDS {
    claimableRewards {
      identifier
      name
      owner
      ticker
      circulatingSupply
      assets {
        pngUrl
      }
      decimals
      isPaused
      canUpgrade
      type
      balance
    }
  }
`;
