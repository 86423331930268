import React, { useState } from 'react';
import './DashboardView.scss';
import { OfferType, OfferTypeEnum } from 'types';
import { DashboardLendBorrowButtons } from './DashboardLendBorrowButtons';
import { DashboardLendBorrowForm } from './DashboardLendBorrowForm';
export const DashboardView = () => {
  const [offerType, setOfferType] = useState<OfferType>(OfferTypeEnum.Lending);
  const onClick = (type: OfferType) => {
    setOfferType(type);
  };
  return (
    <div className='xlvg-dashboard__view'>
      {/* <div className='m-4'>
        <img src={overallGrowth} />
      </div> */}
      <div className='xlvg-dashboard__lend-borrow__form m-4 w-100'>
        <DashboardLendBorrowButtons offerType={offerType} onClick={onClick} />
        <DashboardLendBorrowForm offerType={offerType} />
      </div>
    </div>
  );
};
