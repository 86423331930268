import React from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { GET_TRADING_PAIRS } from 'api/query/tradingPairs';
import { TradingPair } from '../Components';

const useGetTradingPairs = (): {
  data: TradingPair[];
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const { data, loading, error } = useQuery(GET_TRADING_PAIRS);

  return React.useMemo(() => {
    return {
      data: data?.tradingPairs ?? [],
      loading,
      error
    };
  }, [data, loading, error]);
};

export { useGetTradingPairs };
