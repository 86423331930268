import { useEffect } from 'react';
import { Loader } from '@multiversx/sdk-dapp/UI';
import {
  useGetActiveTransactionsStatus,
  useGetBorrowingPositions
} from 'hooks';
import { ActivityContextProvider } from 'pages/Activity/Context';
import '../ActivityCard.scss';
import { useActivityContext } from 'pages/Activity/Context/activity-context';
import { PositionStatus } from 'types';
import { ActivityFilters } from '../ActivityFilters';
import { ActivityItemBorrowing } from '../ActivityItem';
import { useReactiveVar } from '@apollo/client';
import { xlevProVar } from 'helpers/reactive';

export const BorrowingActivity = () => {
  const { data, loading, refetch } = useGetBorrowingPositions();
  const { borrowingFilter } = useActivityContext();
  const xlevPro = useReactiveVar(xlevProVar);

  const { success } = useGetActiveTransactionsStatus();

  useEffect(() => {
    if (success) {
      refetch();
    }
  }, [success]);

  return (
    <div className='activity-card'>
      <span className='activity__title'>
        {!xlevPro ? 'Open positions' : 'Borrowing'}
      </span>
      <ActivityFilters type={'borrowing'} />
      {loading ? (
        <Loader noText />
      ) : (
        data
          .filter(({ status }) =>
            borrowingFilter === PositionStatus.ALL
              ? true
              : status === borrowingFilter
          )
          .map((borrowingPosition, index) => (
            <ActivityItemBorrowing
              key={`borrowing_${index}`}
              item={borrowingPosition}
            />
          ))
      )}
    </div>
  );
};

export const BorrowingActivityWrapper = () => {
  return (
    <ActivityContextProvider>
      <BorrowingActivity />
    </ActivityContextProvider>
  );
};
