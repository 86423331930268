import React, { useEffect, useState } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import BigNumber from 'bignumber.js';
import { DebouncedFunc, debounce } from 'lodash';
import { GET_BORROW_AND_OPEN_POSITION } from 'api/query/borrowAndOpenPosition';
import { BorrowAndOpenPositionResponse, EsdtToken } from 'types';

const useGetBorrowAndOpenPosition = (): {
  data: BorrowAndOpenPositionResponse | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  getBorrowAndOpenPositionData: DebouncedFunc<
    (
      tokenIn: EsdtToken | undefined,
      tokenOut: EsdtToken | undefined,
      amount: BigNumber,
      leverage: number,
      takeProfitAmount: string,
      stopLossAmount: string
    ) => void
  >;
  resetBorrowAndOpenPositionData: () => void;
} => {
  const [stateData, setStateData] = useState<
    BorrowAndOpenPositionResponse | undefined
  >();

  const [borrowAndOpenPosition, { data, loading, error }] = useLazyQuery(
    GET_BORROW_AND_OPEN_POSITION,
    { fetchPolicy: 'no-cache' }
  );

  const getBorrowAndOpenPositionData = React.useCallback(
    debounce(
      (
        tokenIn: EsdtToken | undefined,
        tokenOut: EsdtToken | undefined,
        amount: BigNumber,
        leverage: number,
        takeProfitAmount: string,
        stopLossAmount: string
      ) => {
        if (
          tokenIn === undefined ||
          tokenOut === undefined ||
          amount.isZero()
        ) {
          return;
        }

        borrowAndOpenPosition({
          variables: {
            args: {
              tokenIn: tokenIn?.identifier,
              tokenOut: tokenOut?.identifier,
              tokenInDecimals: tokenIn?.decimals,
              tokenOutDecimals: tokenOut?.decimals,
              amount: amount.toFixed(),
              leverage,
              takeProfitAmount: takeProfitAmount || undefined,
              stopLossAmount: stopLossAmount || undefined
            }
          }
        });
      },
      500
    ),
    []
  );

  const resetBorrowAndOpenPositionData = () => {
    setStateData(undefined);
  };

  useEffect(() => {
    setStateData(
      data?.borrowAndOpenPosition !== undefined
        ? BorrowAndOpenPositionResponse.fromResponse(data.borrowAndOpenPosition)
        : undefined
    );
  }, [data?.borrowAndOpenPosition]);

  return React.useMemo(() => {
    return {
      data: stateData,
      loading,
      error,
      getBorrowAndOpenPositionData,
      resetBorrowAndOpenPositionData
    };
  }, [stateData, loading, error]);
};

export { useGetBorrowAndOpenPosition };
