import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useGetActiveTransactionsStatus, useGetClaimableRewards } from 'hooks';
import { ClaimableReward } from './ClaimableReward';
import './ClaimableRewards.scss';

export const ClaimableRewards = () => {
  const { data: items, loading, error, refetch } = useGetClaimableRewards();

  const { success } = useGetActiveTransactionsStatus();

  useEffect(() => {
    if (success) {
      refetch();
    }
  }, []);

  return (
    <div className='claimable-rewards__wrapper'>
      <div className='claimable-rewards__title'>Claim your rewards</div>
      {loading ? (
        <Spinner as='span' animation='border' variant='primary' />
      ) : error ? (
        <span className='text-warning'>Cannot fetch rewards</span>
      ) : items && items.length > 0 ? (
        <div className='claimable-rewards__items'>
          {items.map((item) => (
            <ClaimableReward item={item} key={item.identifier} />
          ))}
        </div>
      ) : (
        <span className='claimable-rewards__message'>No rewards</span>
      )}
    </div>
  );
};
