export const contractAddress =
  'erd1qqqqqqqqqqqqqpgq72l6vl07fkn3alyfq753mcy4nakm0l72396qkcud5x';

export const dAppName = 'xLeverage';

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
export const walletConnectV2ProjectId = '5598fdce98b11f7ecba4796ded244dcd';
export const updateRefreshRate = 60000;

export const apiUri = 'https://devnet-api.xlvrg.app/graphql';
export const apiTimeout = 6000;
export const transactionSize = 15;
export const TOOLS_API_URL = 'https://tools.multiversx.com';

export const TOKEN_PLACEHOLDER = '/public_assets/token_placeholder.svg';
export const maxPeriod = 30;
export const egldIdentifier = 'EGLD';
export const multiversxApi = 'https://devnet-api.multiversx.com';
