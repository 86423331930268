import { useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { useTradeContext } from 'pages/Trade/context';
import { EsdtToken } from 'types';
import CheckboxOption from './CheckboxOption';

interface TakeProfitStopLossProps {
  tokenIn?: EsdtToken;
  tokenOut?: EsdtToken;
  entryPrice?: string;
  positionSize?: string;
  liquidationPrice?: string;
}

const TakeProfitStopLoss = ({
  tokenIn,
  tokenOut,
  entryPrice,
  positionSize,
  liquidationPrice
}: TakeProfitStopLossProps) => {
  const {
    stopLossLimit,
    takeProfitLimit,
    setStopLossLimit,
    setTakeProfitLimit
  } = useTradeContext();

  const entryPriceDenom = useMemo(() => {
    if (!entryPrice || !tokenIn) {
      return '0';
    }

    return new BigNumber(entryPrice).shiftedBy(-tokenIn.decimals).toFixed();
  }, [entryPrice]);

  return (
    <div className='w-100 d-flex flex-column my-3'>
      <CheckboxOption
        text='Take Profit'
        info='A take profit is an order you place on your trades to exit a position when the market moves in your favor.'
        tokenIn={tokenIn}
        tokenOut={tokenOut}
        entryPrice={entryPrice}
        positionSize={positionSize}
        min={entryPriceDenom}
        limit={takeProfitLimit}
        setTradeLimit={setTakeProfitLimit}
      />
      <CheckboxOption
        limit={stopLossLimit}
        setTradeLimit={setStopLossLimit}
        text='Stop Loss'
        className='mt-3'
        info='A stop-loss is an order you place to your trades to exit a position if the market moves against your plan.'
        tokenIn={tokenIn}
        tokenOut={tokenOut}
        entryPrice={entryPrice}
        positionSize={positionSize}
        max={entryPriceDenom}
        min={liquidationPrice}
      />
    </div>
  );
};

export default TakeProfitStopLoss;
