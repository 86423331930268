import {
  CircleInactiveIcon,
  FilterSelectedIcon,
  FilterUnselectedIcon
} from 'assets/img';
import './ActivityFilters.scss';

interface ActivityFilterProps {
  title: string;
  selected: boolean;
  showCircleIcon: boolean;
  onFilterSelected: () => void;
}

export const ActivityFilter = ({
  title,
  selected,
  showCircleIcon,
  onFilterSelected
}: ActivityFilterProps) => {
  const handleOnClick = () => {
    if (selected) {
      return;
    }

    onFilterSelected();
  };

  return (
    <div
      className={`activity-filters__item ${
        selected ? 'activity-filters__item--selected' : ''
      }`}
      onClick={handleOnClick}
    >
      {showCircleIcon && (
        <CircleInactiveIcon className='activity-filters__icon' />
      )}
      <span>{title}</span>
      {selected ? <FilterSelectedIcon /> : <FilterUnselectedIcon />}
    </div>
  );
};
