import React from 'react';
import './DashboardLendBorrowForm.scss';
import { useMutation } from '@apollo/client';
import BigNumber from 'bignumber.js';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Spinner } from 'react-bootstrap';
import { CREATE_OFFER } from 'api/mutations/offer';
import { sendAndSignTransactions } from 'api/transaction';
import ActionOrConnect from 'components/ActionOrConnect';
import { egldIdentifier, maxPeriod } from 'config';
import { toFixedWithoutRounding } from 'helpers';
import { useGetAccountTokens, useGetActiveTransactionsStatus } from 'hooks';
import { useHomeContextConfig } from 'pages/Home/context';
import { OfferType, OfferTypeEnum } from 'types';
import { ReactComponent as Info } from '../../../../assets/img/info.svg';

export const DashboardLendBorrowForm = ({
  offerType
}: {
  offerType: OfferType;
}) => {
  const isLendingSelected = offerType === OfferTypeEnum.Lending;
  const {
    selectedToken,
    apr,
    setApr,
    period,
    setPeriod,
    tokenAmount,
    setTokenAmount
  } = useHomeContextConfig();
  const { data: tokens } = useGetAccountTokens();
  const [
    createOfferTransaction,
    { data: createOfferData, loading: createOfferLoading }
  ] = useMutation(CREATE_OFFER);

  const { pending, success } = useGetActiveTransactionsStatus();

  const [leverage, setLeverage] = React.useState<string>('');

  const validForm =
    apr !== '' &&
    period !== '' &&
    tokenAmount !== '' &&
    (!isLendingSelected ? leverage !== '' : true);

  const handleSign = () => {
    if (!validForm) return;

    let args = {
      tokenId: selectedToken?.identifier,
      amount: new BigNumber(tokenAmount)
        .shiftedBy(selectedToken?.decimals || 0)
        .toString(),
      feePercentage: +apr,
      offerType: isLendingSelected ? 'LENDING' : 'BORROWING',
      maxDuration: +period
    };

    if (!isLendingSelected) {
      args = { ...args, ...{ leverage: +leverage } };
    }
    createOfferTransaction({
      variables: {
        args
      }
    });
  };

  const maxAmount =
    (tokens &&
      selectedToken &&
      new BigNumber(
        tokens.find(
          ({ identifier }) => identifier === selectedToken?.identifier
        )?.balance || 0
      )
        .shiftedBy(-selectedToken.decimals)
        .toNumber() -
        (selectedToken.identifier === egldIdentifier ? 0.05 : 0)) ||
    undefined;
  const receivableAmount =
    +tokenAmount + ((+tokenAmount * (+apr / 100)) / 365) * +period;

  const loading = pending || createOfferLoading;

  React.useEffect(() => {
    if (createOfferData && createOfferData.createOffer) {
      const transactionDisplayInfoType = {
        processingMessage: 'Create the offer',
        errorMessage: 'An error has occurred while creating the offer',
        successMessage: 'The offer has been created successfully'
      };
      sendAndSignTransactions(
        createOfferData.createOffer,
        transactionDisplayInfoType
      );
    }
  }, [createOfferData?.createOffer]);

  React.useEffect(() => {
    if (success) {
      setLeverage('');
      setApr('');
      setPeriod('');
      setTokenAmount('');
    }
  }, [success]);

  const handleAprChange = (event: any) => {
    const input = event.target.value;
    // Allow up to 2 decimal places
    const regex = /^(\d*\.?\d{0,2})$/;

    if (regex.test(input)) {
      setApr(input);
    }
  };

  return (
    <div className='d-flex flex-column'>
      <div className='d-flex'>
        <div
          className={`d-flex flex-column${
            !isLendingSelected ? ' align-items-end' : ''
          }`}
        >
          <div className='d-flex'>
            <div className='input__wrapper input__wrapper-border'>
              <input
                type='number'
                placeholder='Amount'
                value={tokenAmount}
                min={0.1}
                max={maxAmount}
                onChange={(event) =>
                  setTokenAmount(
                    maxAmount !== undefined && +event.target.value >= maxAmount
                      ? maxAmount.toString()
                      : event.target.value
                  )
                }
              />
              <div
                className='max-button mx-2'
                onClick={() =>
                  maxAmount !== undefined &&
                  setTokenAmount(maxAmount.toString())
                }
              >
                Max
              </div>
            </div>
            <div className='input__wrapper input__wrapper-border direction-column px-4'>
              <div className='direction-row'>
                <input
                  type='number'
                  placeholder='Period'
                  value={period}
                  onChange={(event) =>
                    setPeriod(
                      +event.target.value <= maxPeriod
                        ? event.target.value
                        : maxPeriod.toString()
                    )
                  }
                  min={1}
                  max={maxPeriod}
                />
                <div
                  className='max-button'
                  onClick={() => setPeriod(maxPeriod.toString())}
                >
                  Max
                </div>
              </div>
              <Slider
                className='xlvg-slider'
                min={1}
                max={maxPeriod}
                value={+period}
                onChange={(value: number | number[]) =>
                  !Array.isArray(value) && setPeriod(value.toString())
                }
              />
            </div>
          </div>
          {isLendingSelected ? (
            <div className='mt-4'>
              <div className='info-title'>Receive:</div>
              <span className='info-subtitle'>AFTER THE PERIOD</span>
              <div className='d-flex align-items-center'>
                <Info className='mr-2' />
                <span className='info-subtitle'>
                  After your offer is matched you will have boosted
                </span>
              </div>
            </div>
          ) : (
            <div className='input__wrapper input__wrapper-border direction-column mt-4 px-4'>
              <div className='direction-row'>
                <input
                  type='number'
                  placeholder='Leverage'
                  value={leverage}
                  min={2}
                  max={10}
                  onChange={(event) =>
                    setLeverage(
                      +event.target.value <= 10 ? event.target.value : '10'
                    )
                  }
                />
              </div>
              <Slider
                className='xlvg-slider'
                min={2}
                max={10}
                value={+leverage}
                onChange={(value: number | number[]) =>
                  !Array.isArray(value) && setLeverage(value.toString())
                }
              />
            </div>
          )}
        </div>
        <div className='d-flex flex-column'>
          <div className='d-flex'>
            <div className='input__wrapper input__wrapper-border direction-column mx-4 px-4'>
              <div className='direction-row'>
                <input
                  type='number'
                  placeholder='APR'
                  value={apr}
                  onChange={handleAprChange}
                />
                {/* <div className='max-button'>
                  Market apr <Info className='mx-1' />
                </div> */}
              </div>
              <Slider
                className='xlvg-slider'
                min={0.01}
                max={99}
                value={+apr}
                step={0.1}
                onChange={(value: number | number[]) =>
                  !Array.isArray(value) && setApr(value.toString())
                }
              />
            </div>
          </div>
          <div className='d-flex mt-4 align-items-center'>
            <div
              className={`input__wrapper input__wrapper-border direction-column justify-content-around mx-4 p-3 ${
                !isLendingSelected ? 'input__hidden' : ''
              }`}
            >
              <span>
                {toFixedWithoutRounding(receivableAmount, 6)}{' '}
                {selectedToken?.identifier.split('-')[0]}
              </span>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column'>
          {/* <div className='input__wrapper d-flex align-items-center justify-content-start'>
            <div className='d-flex flex-column justify-content-center mx-2'>
              <div className='info-title'>
                Current market <br /> APR : 3.8%
              </div>
            </div>
            <div className='info-text info-text-green mx-2'>2.90%</div>
          </div> */}

          <ActionOrConnect className='xlvg-button xlvg-button-connect'>
            <button
              className='xlvg-button mt-2'
              onClick={handleSign}
              disabled={loading}
            >
              {loading ? (
                <Spinner as='span' animation='border' size='sm' />
              ) : (
                'Sign'
              )}
            </button>
          </ActionOrConnect>
        </div>
      </div>
    </div>
  );
};
