import { useMemo } from 'react';
import Select from 'react-select';
import {
  Leverage,
  LeverageOption
} from 'pages/Trade/Components/LeverageOption';
import { leverageStyles } from './leverageStyles';

interface TradeLeverageProps {
  leverage: Leverage;
  setLeverage: (newVal: Leverage) => void;
}

export const TradeLeverage = ({
  leverage,
  setLeverage
}: TradeLeverageProps) => {
  const leverageOptions = useMemo(() => {
    let options = Array.from({ length: 10 }, (_, index) => index + 1).map(
      (opt) => {
        return { value: opt.toString() } as Leverage;
      }
    );

    options = options.slice(1);

    return options;
  }, []);

  return (
    <div className='d-flex flex-column mt-3 align-self-start'>
      <span className='trade__text flex-grow-1'>Leverage</span>
      <div className='d-flex flex-row align-items-center py-2 px-3 mt-2 amount-with-leverage'>
        <Select
          styles={leverageStyles}
          options={leverageOptions}
          value={leverage}
          formatOptionLabel={LeverageOption}
          isSearchable={false}
          components={{
            IndicatorSeparator: () => null
          }}
          onChange={(value) => value && setLeverage(value)}
        />
      </div>
    </div>
  );
};
