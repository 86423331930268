import React from 'react';
import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client';
import { GET_BORROWING_OFFERS, GET_LENDING_OFFERS } from 'api/query';
import { Offer, OfferType, OfferTypeEnum } from 'types';

export const POLLING_INTERVAL = 6000;

const useGetOffers = (
  offerType: OfferType,
  tokenId: string | undefined
): {
  data: Offer[];
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => Promise<ApolloQueryResult<any>>;
} => {
  const { data, loading, error, refetch } = useQuery(
    offerType === OfferTypeEnum.Lending
      ? GET_LENDING_OFFERS
      : GET_BORROWING_OFFERS,
    {
      skip: tokenId === undefined,
      variables: {
        tokenId
      }
    }
  );

  return React.useMemo(() => {
    return {
      data: data?.offers.map(Offer.fromResponse) || [],
      loading,
      error,
      refetch
    };
  }, [data, loading, error]);
};

export { useGetOffers };
