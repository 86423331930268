import React, { useContext } from 'react';
import { PositionStatus } from 'types';

export interface ActivityContextData {
  lendingFilter:
    | PositionStatus.ALL
    | PositionStatus.ACTIVE
    | PositionStatus.INACTIVE;
  borrowingFilter:
    | PositionStatus.ALL
    | PositionStatus.ACTIVE
    | PositionStatus.INACTIVE;
  setLendingFilter: React.Dispatch<
    React.SetStateAction<
      PositionStatus.ALL | PositionStatus.ACTIVE | PositionStatus.INACTIVE
    >
  >;
  setBorrowingFilter: React.Dispatch<
    React.SetStateAction<
      PositionStatus.ALL | PositionStatus.ACTIVE | PositionStatus.INACTIVE
    >
  >;
}

export const ActivityContext = React.createContext<ActivityContextData>({
  lendingFilter: PositionStatus.ALL,
  borrowingFilter: PositionStatus.ALL,
  setLendingFilter: () => {
    console.log('Unimplemented');
  },
  setBorrowingFilter: () => {
    console.log('Unimplemented');
  }
});

export function useActivityContext(): ActivityContextData {
  const context = useContext(ActivityContext);
  if (context == null) {
    throw new Error('HomeContext is null');
  }

  return context;
}
