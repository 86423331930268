export const leverageStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    color: 'white'
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: 'white',
    '&:hover': {
      color: 'white'
    }
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#17153a',
    color: 'white',
    minWidth: '120px',
    right: '0px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? 'black' : 'white',
    backgroundColor: state.isSelected ? '#676c72' : 'transparent',
    ':active': {
      ...provided[':active'],
      backgroundColor: '#676c72'
    }
  })
};
