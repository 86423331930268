import { gql } from '@apollo/client';

export const GET_LENDING_OFFERS = gql`
  query GET_LENDING_OFFERS($tokenId: String!) {
    offers: lendingOffers(tokenId: $tokenId) {
      ids
      token {
        identifier
        name
        ticker
        assets {
          svgUrl
          pngUrl
        }
        balance
        decimals
        supply
      }
      amount
      apr
      maxDuration
      __typename
    }
  }
`;

export const GET_BORROWING_OFFERS = gql`
  query GET_BORROWING_OFFERS($tokenId: String!) {
    offers: borrowingOffers(tokenId: $tokenId) {
      ids
      token {
        identifier
        name
        ticker
        assets {
          svgUrl
          pngUrl
        }
        balance
        decimals
        supply
      }
      amount
      apr
      maxDuration
      __typename
    }
  }
`;
