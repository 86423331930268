import React from 'react';
import { updateRefreshRate } from 'config';
import { useCheckVersion } from 'hooks';

export const UpdateNotification = () => {
  const [updateAvailable, refreshPage] = useCheckVersion({
    refreshRate: updateRefreshRate
  });

  return updateAvailable ? (
    <div className='d-flex justify-content-between align-items-center px-3 py-3 px-md-4 bg-primary text-light font-weight-bold update-notification'>
      A new version of the xLeverage is available.
      <a
        href='/#'
        onClick={refreshPage}
        className='ml-1 text-black font-weight-bold'
      >
        <span className='text-light'>Reload</span>
      </a>
    </div>
  ) : (
    <></>
  );
};
