import Select from 'react-select';
import { EsdtToken } from 'types';
import { TradePairOption } from '../TradePairOption';
import { tradingPairStyles } from './tradingPairStyles';

interface TradePairSelectProps {
  loading: boolean;
  tradingPairs: TradingPair[];
  selectedTradingPair: TradingPair | undefined;
  onTradingPairChanged: (pair: TradingPair) => void;
}

export type TradingPair = {
  firstToken: EsdtToken | undefined;
  secondToken: EsdtToken | undefined;
};

export const TradePairSelect = ({
  loading,
  tradingPairs,
  selectedTradingPair,
  onTradingPairChanged
}: TradePairSelectProps) => {
  return (
    <div className='d-flex flex-row w-100 mb-3'>
      <Select
        styles={tradingPairStyles}
        options={tradingPairs}
        value={selectedTradingPair}
        formatOptionLabel={TradePairOption}
        isSearchable={false}
        isLoading={loading}
        components={{
          IndicatorSeparator: () => null
        }}
        getOptionValue={(option) =>
          `${option?.firstToken?.identifier}/${option?.secondToken?.identifier}`
        }
        onChange={(value) => value && onTradingPairChanged(value)}
      />
    </div>
  );
};

export default TradePairSelect;
