import React, { useEffect, useRef, useState } from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import { AuthenticatedRoutesWrapper } from 'components';
import Sidebar from 'components/Sidebar';
import useClickOutside from 'hooks/useClickOutside';
import useMediaQuery from 'hooks/useMediaQuery';
import { routes, routeNames } from 'routes';
import './Layout.scss';

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const isDesktop = useMediaQuery('(min-width: 992px)');

  const [menuShown, setMenuShown] = useState(isDesktop);

  useClickOutside([sidebarRef], () => {
    if (isDesktop) {
      return;
    }

    setMenuShown(false);
  });

  useEffect(() => {
    if (isDesktop) {
      return;
    }

    setMenuShown(false);
  }, [location]);

  useEffect(() => {
    setMenuShown(isDesktop);
  }, [isDesktop]);

  return (
    <div className='d-flex flex-fill wrapper'>
      <Sidebar
        forwardedRef={sidebarRef}
        className={`${menuShown ? 'entering' : 'exiting'}`}
      />
      <FontAwesomeIcon
        icon={faBars}
        size='xl'
        color='white'
        className='burger-menu'
        onClick={() => setMenuShown((prev) => !prev)}
      />
      <main className='d-flex flex-column flex-grow-1'>
        <AuthenticatedRoutesWrapper
          routes={routes}
          unlockRoute={`${routeNames.unlock}${location.search}`}
        >
          {children}
        </AuthenticatedRoutesWrapper>
      </main>
      {/* <Footer /> */}
    </div>
  );
};
