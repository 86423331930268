import React from 'react';
import { WithClassnameType } from '@multiversx/sdk-dapp/UI/types';
import Select, { SingleValue } from 'react-select';
import { TOKEN_PLACEHOLDER, egldIdentifier } from 'config';
import { useGetTokens } from 'hooks';
import { useHomeContextConfig } from 'pages/Home/context';
import { EsdtToken } from 'types';
import './TokenSelect.scss';
import { selectColourStyles } from './util';
export const TokenSelect = ({ className }: WithClassnameType) => {
  const { selectedToken, setSelectedToken, setApr, setPeriod, setTokenAmount } =
    useHomeContextConfig();

  const { data: tokens, loading } = useGetTokens();
  const formatOptionLabel = (option: EsdtToken) => {
    return (
      <div className='d-flex flex-row align-items-center'>
        <img
          src={option.icon ?? TOKEN_PLACEHOLDER}
          className='xlvg-select-icon p-0 mr-2'
        />
        <span className='xlvg-select__input-title'>
          {option.identifier.split('-')[0]}
        </span>
      </div>
    );
  };
  const selectOption = (esdtToken: SingleValue<EsdtToken>) => {
    if (esdtToken) {
      setApr('');
      setPeriod('');
      setTokenAmount('');
      setSelectedToken(esdtToken);
    }
  };

  const preselectOptionOnLoad = () => {
    if (tokens?.length === 1) {
      selectOption(tokens[0]);
    } else if (tokens?.length && tokens?.length > 1) {
      const egld = tokens?.find(
        ({ identifier }) => identifier === egldIdentifier
      );
      egld && selectOption(egld);
    }
  };

  React.useEffect(() => {
    preselectOptionOnLoad();
  }, [tokens]);
  return (
    <>
      <Select
        placeholder={'Select token'}
        classNamePrefix={'xlvg-select'}
        className={`xlvg-select ${className || ''}`}
        isLoading={loading}
        options={tokens}
        styles={selectColourStyles('#082033', '#1D5D9B', '#75C2F6')}
        formatOptionLabel={(option) => formatOptionLabel(option)}
        getOptionValue={(option) => option.identifier}
        value={selectedToken}
        onChange={selectOption}
      />
    </>
  );
};
