import { getIsLoggedIn } from '@multiversx/sdk-dapp/utils';
import { ClaimableRewards } from 'components';
import { Impersonate } from 'components/Impersonate';
import {
  ActivityNavbar,
  BorrowingActivityWrapper,
  LendingActivityWrapper
} from './Components';
import { useReactiveVar } from '@apollo/client';
import { xlevProVar } from 'helpers/reactive';

export const ActivityPage = () => {
  const xlevPro = useReactiveVar(xlevProVar);

  return (
    <div className='d-flex flex-column flex-fill container-fluid overflow-hidden'>
      <ActivityNavbar />
      <Impersonate />
      {getIsLoggedIn() && xlevPro && <ClaimableRewards />}
      {xlevPro && <LendingActivityWrapper />}
      <BorrowingActivityWrapper />
    </div>
  );
};
