import { formatTicker } from 'helpers/formatTicker';
import { TradingPair } from '../TradePairSelect';

export const TradePairOption = (pair: TradingPair) => {
  if (pair.firstToken === undefined || pair.secondToken === undefined) {
    return null;
  }

  return (
    <div className='d-flex flex-row items-center'>
      {formatTicker(pair.firstToken?.identifier)}/
      {formatTicker(pair.secondToken?.identifier)}
    </div>
  );
};
