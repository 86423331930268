import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import './InfoModal.scss';

interface InfoModalProps {
  title: string;
  subtitle: string;
  show: boolean;
  onHide: () => void;
}

const InfoModal = ({ title, subtitle, show, onHide }: InfoModalProps) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <span className='btn btn-primary btn-xs' onClick={onHide}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </Modal.Header>
      <Modal.Body className='d-flex flex-column'>
        <span>{subtitle}</span>
      </Modal.Body>
    </Modal>
  );
};

export default InfoModal;
